import request from '@/utils/request'

// 商城秒杀分页列表
export function seckillList(params) {
    return request({
        url: '/api/system/mall/marketing/seckill/list',
        method: 'get',
        params: params
    })
}
// 审核
export function seckillAudit (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/audit',
        method: 'put',
        data
    })
}
// 复制
export function seckillCopyActivity (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/copyActivity',
        method: 'post',
        data
    })
}
// 启用/禁用
export function seckillUpdateIsEnable (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/updateIsEnable',
        method: 'put',
        data
    })
}
// 删除
export function seckillDelete (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/delete',
        method: 'delete',
        data
    })
}
// 新增拼团活动
export function seckillSave (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/save',
        method: 'post',
        data
    })
}
// 修改拼团活动
export function seckillUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/seckill/update',
        method: 'put',
        data
    })
}
// 详情
export function seckillGetDetail(params) {
    return request({
        url: '/api/system/mall/marketing/seckill/getDetail',
        method: 'get',
        params: params
    })
}
// 活动商品校验
export function seckillVerificationActivityGoods(params) {
    return request({
        url: '/api/system/mall/marketing/seckill/verificationActivityGoods',
        method: 'get',
        params: params
    })
}
// 活动商品校验
export function bargainActivityGoods(params) {
    return request({
        url: '/api/system/mall/marketing/seckill/bargainActivityGoods',
        method: 'get',
        params: params
    })
}
