import request from '@/utils/request'

//折扣率分析分页列表
export function discountRateList(params) {
  return request({
    url: '/api/system/shop/strategy/discountRate/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//折扣率分析分页汇总
export function discountRateListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/discountRate/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//折扣分析趋势分页列表
export function discountRateListTrend(params) {
  return request({
    url: '/api/system/shop/strategy/discountRate/listTrend',
    method: 'get',
    timeout: 30000,
    params
  })
}
