import request from '@/utils/request'

// 仓库设置列表
export function listStore (query) {
  return request({
    url: '/api/system/goods/store/list',
    method: 'get',
    params: query
  })
}

// 查询仓库设置详细
export function getStoreDetail (storeId) {
  return request({
    url: '/api/system/goods/store/getDetail',
    method: 'get',
    params: { storeId }
  })
}

// 新增仓库设置
export function addStore (data) {
  return request({
    url: '/api/system/goods/store/save',
    method: 'post',
    data: data
  })
}

// 修改仓库设置
export function updateStore (data) {
  return request({
    url: '/api/system/goods/store/update',
    method: 'put',
    data: data
  })
}

// 删除仓库设置
export function delStore (storeIds) {
  return request({
    url: '/api/system/goods/store/remove',
    method: 'delete',
    data: storeIds
  })
}

// 获取仓库收货地址
export function getStoreAddress (storeId) {
  return request({
    url: `/api/system/goods/store/getStoreAddress?storeId=${storeId}`
  })
}

// 获取仓库收货地址
export function listPartnerLinkman (partnerId) {
  return request({
    url: `/api/system/partner/linkman/listPartnerLinkman?partnerId=${partnerId}`
  })
}
// 还原仓库
export function restoreStore (storeIds) {
  return request({
    url: '/api/system/goods/store/restoreStore',
    method: 'POST',
    data: { storeIds }
  })
}

// 仓库资料自动编号
export function getGoodsStoreAutoNo () {
  return request({
    url: '/api/system/goods/store/getGoodsStoreAutoNo',
    method: 'get'
  })
}
