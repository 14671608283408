import request from '@/utils/request'

//决策门店销售排名分页列表
export function shopsSaleList(params) {
  return request({
    url: '/api/system/shop/strategy/shopSale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//决策门店销售排名分页汇总
export function shopsSaleListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/shopSale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
