import request from '@/utils/request' //门店调出
// 门店调货列表
export function franchiseListOrder(query) {
  return request({
    url: '/api/system/franchise/shop/bill/out/list',
    method: 'get',
    params: query
  })
}

//更新门店调货单据状态
export function franchiseUpdateOrderStatus(data) {
  return request({
    url: '/api/system/franchise/shop/bill/out/updateBillStatus',
    method: 'post',
    data
  })
}
//修改门店调货单据
export function franchiseUpdateOrder(data) {
  return request({
    url: '/api/system/franchise/shop/bill/out/update',
    method: 'PUT',
    data
  })
}

//获取门店调货单据详情
export function franchiseGetOrderDetail(billId) {
  return request({
    url: `/api/system/franchise/shop/bill/out/getDetail?billId=${billId}`
  })
}

//新增门店调货单据
export function franchiseAddOrder(data) {
  return request({
    url: '/api/system/franchise/shop/bill/out/save',
    method: 'POST',
    data
  })
}
