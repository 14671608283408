//商品加值属性 接口

import request from '@/utils/request'

// 加值属性分页列表
export function listGoodsMachining(data) {
  return request({
    url: '/api/system/goods/machining/list',
    method: 'post',
    data
  })
}

//获取加值属性所有数据
export function allGoodsMachining() {
  return request({
    url: '/api/system/goods/machining/queryGroup'
  })
}

//加值属性详情
export function getGoodsMachiningDetail(machiningGroupId) {
  return request({
    url: `/api/system/goods/machining/getDetail?machiningGroupId=${machiningGroupId}`,
    method: 'get'
  })
}

// 新增加值属性
export function addGoodsMachining(data) {
  return request({
    url: '/api/system/goods/machining/save',
    method: 'post',
    data: data
  })
}

// 修改加值属性
export function updateGoodsMachining(data) {
  return request({
    url: '/api/system/goods/machining/update',
    method: 'put',
    data: data
  })
}

// 删除商品资料
export function delGoodsMachining(machiningGroupIds) {
  return request({
    url: '/api/system/goods/machining/delete',
    method: 'delete',
    data: machiningGroupIds
  })
}

// 还原商品属性
export function restore(data) {
  return request({
    url: '/api/system/goods/machining/restore',
    method: 'POST',
    data
  })
}

// 删除的商品属性
export function getDelGoodsMachining(params) {
  return request({
    url: '/api/system/goods/machining/getDelGoodsMachining',
    method: 'get',
    params
  })
}

// 还原商品属性
export function restoreGoodsMachining(data) {
  return request({
    url: '/api/system/goods/machining/restoreGoodsMachining',
    method: 'post',
    data
  })
}