import request from '@/utils/request'

// 商城支付配置详情
export function wxAppGetDetail(params) {
    return request({
        url: '/api/system/tenant/wxApp/getDetail',
        method: 'get',
        params: params
    })
}

// 新增商城配置
export function wxAppSave(data) {
    return request({
        url: '/api/system/tenant/wxApp/appSave',
        method: 'post',
        data
    })
}

// // 编辑商城配置
// export function wxAppUpdate(data) {
//     return request({
//         url: '/api/system/mall/config/wx/app/update',
//         method: 'put',
//         data
//     })
// }
// 获取小程序授权地址
export function authorizationImageUrl(params) {
    return request({
        url: '/api/open/getAppletAuthorizationImageUrl',
        method: 'get',
        params: params
    })
}
// 获取平台商户微信执行步骤
export function wxAppGetStep(params) {
    return request({
        url: '/api/system/tenant/wxApp/getStep',
        method: 'get',
        params: params
    })
}
// 设置平台商户微信执行步骤
export function wxAppSetStep(data) {
    return request({
        url: '/api/system/tenant/wxApp/setStep',
        method: 'post',
        data
    })
}
// 获取平台商户微信执行步骤
export function tenantWxAppGetDetail(params) {
    return request({
        url: '/api/system/tenant/wxApp/getDetail',
        method: 'get',
        params: params
    })
}
