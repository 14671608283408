import request from '@/utils/request'

// 商品师傅列表
export function listBrand (query) {
  return request({
    url: '/api/system/freshly/baked/worker/list',
    method: 'get',
    params: query
  })
}

// 查询商品师傅详细
export function getBrandDetail (workerId) {
  return request({
    url: '/api/system/freshly/baked/worker/getDetail?workerId=' + workerId,
    method: 'get'
  })
}

// 新增商品师傅
export function addBrand (data) {
  return request({
    url: '/api/system/freshly/baked/worker/save',
    method: 'post',
    data: data
  })
}

// 修改商品师傅
export function updateBrand (data) {
  return request({
    url: '/api/system/freshly/baked/worker/update',
    method: 'put',
    data: data
  })
}

// 删除商品师傅
export function delBrand (workerIds) {
  return request({
    url: '/api/system/freshly/baked/worker/delete',
    method: 'delete',
    data: workerIds
  })
}
//批量更新师傅状态
export function statusBrand (data) {
  return request({
    url: '/api/system/goods/brand/updateStatus',
    method: 'delete',
    data
  })
}
// 查询商品师傅详细
export function getGoodsBrandAutoNo () {
  return request({
    url: '/api/system/goods/brand/getGoodsBrandAutoNo',
    method: 'get'
  })
}
//还原商品师傅
export function restoreGoodsBrandInfo (workerId) {
  return request({
    url: '/api/system/freshly/baked/worker/reduction',
    method: 'post',
    data: workerId
  })
}
