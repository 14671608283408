import request from '@/utils/request'

//退货率分析分页列表
export function backRateList(params) {
  return request({
    url: '/api/system/shop/strategy/returnRateAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//退货率分析分页汇总
export function backRateListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/returnRateAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//退货分析趋势分页列表
export function backRateListTrend(params) {
  return request({
    url: '/api/system/shop/strategy/returnRateAnalysis/listTrend',
    method: 'get',
    timeout: 30000,
    params
  })
}
