import request from '@/utils/request'

// 商品资料列表
export function listGoods(query) {
  return request({
    url: '/api/system/goods/info/list',
    method: 'get',
    params: query
  })
}
// 线上商城过滤未上架的商品资料列表
export function listGoodsInfoList(query) {
  return request({
    url: '/api/system/goods/info/listMallGoods',
    method: 'get',
    params: query
  })
}

// 扫码点餐过滤未上架的商品资料列表
export function listOrdermealGoods(query) {
  return request({
    url: '/api/system/goods/info/listOrdermealGoods',
    method: 'get',
    params: query
  })
}

// 查询商品资料详细
export function getGoodsDetail(goodsId) {
  return request({
    url: `/api/system/goods/info/getDetail?goodsId=${goodsId}`,
    method: 'get'
  })
}

// 新增商品资料
export function addGoods(data) {
  return request({
    url: '/api/system/goods/info/save',
    method: 'post',
    data: data
  })
}

// 修改商品资料
export function updateGoods(data) {
  return request({
    url: '/api/system/goods/info/update',
    method: 'put',
    data: data
  })
}

//批量修改商品
export function batchUpdateGoods(data) {
  return request({
    url: '/api/system/goods/info/batchUpdateGoods',
    method: 'put',
    data: data
  })
}

// 删除商品资料
export function delGoods(goodsIds) {
  return request({
    url: '/api/system/goods/info/remove',
    method: 'delete',
    data: goodsIds
  })
}

//获取商品换算单位
export function getUnitGoods(goodsId) {
  return request({
    url: `/api/system/goods/unit/getUnitName?goodsId=${goodsId}`,
  })
}

//获取商品多规格单位id
export function getGoodsUnitId() {
  return request({
    url: '/api/open/common/getGlobalId'
  })
}

//获取多规格单位数据
export function getGoodsUnitDictData() {
  return request({
    url: '/api/system/dict/data/getGoodsUnitDictData'
  })
}

//添加商品单位字典数据
export function saveGoodsUnitDictData(dictLabel) {
  return request({
    url: `/api/system/dict/data/saveGoodsUnitDictData`,
    method: 'post',
    data: {
      dictLabel
    }
  })
}

//编辑商品单位字典数据
export function updateGoodsUnitDictData(data) {
  return request({
    url: `/api/system/dict/data/updateGoodsUnitDictData`,
    method: 'post',
    data
  })
}

//删除商品单位字典数据
export function delGoodsUnitDictData(data) {
  return request({
    url: `/api/system/dict/data/delete`,
    method: 'delete',
    data
  })
}

//获取多规格列表
export function multiSpecList(query) {
  return request({
    url: `/api/system/goods/unit/list`,
    method: 'get',
    params: query
  })
}

//根据id获取商品列表
export function getGoodsList(goodsIds) {
  return request({
    url: `/api/system/goods/info/findByGoodsIds`,
    method: 'post',
    data: goodsIds
  })
}

//获取商户所有商品列表 (id/名称/编号)
export function getAllGoodsList() {
  return request({
    url: `/api/system/goods/info/getAllGoods`,
    method: 'post'
  })
}

// 积分兑换商品资料分页列表
export function scoreGoodsList(query) {
  return request({
    url: '/api/system/goods/info/scoreGoodsList',
    method: 'get',
    params: query
  })
}

// 还原商品类别
export function restoreGoodsInfo(goodsIds) {
  return request({
    url: '/api/system/goods/info/restoreGoodsInfo',
    method: 'POST',
    data: { goodsIds }
  })
}

// 获取编码号(添加商品)  最新
export function getGoodsAutoNo(categoryId) {
  return request({
    url: `/api/system/goods/info/getGoodsAutoNo?categoryId=${categoryId}`,
    method: 'get'
  })
}

// 商品资料树型分页列表(tree)
export function listTreePage(params) {
  return request({
    url: '/api/system/goods/info/listTreePage',
    method: 'get',
    params
  })
}

//复制商品资料
export function serviceCopyAPI(data) {
  return request({
    url: `/api/system/goods/info/copyGoods`,
    method: 'post',
    data
  })
}

// 获取商品多规格信息最大后缀编号
export function getMaxGoodsUnitSn(goodsId) {
  return request({
    url: `/api/system/goods/info/getMaxGoodsUnitSn?goodsId=${goodsId}`,
    method: 'get'
  })
}