import request from '@/utils/request'

// 现烤订单订货列表
export function listOrder (query) {
  return request({
    url: '/api/system/shop/bill/freshly/baked/order/list',
    method: 'get',
    params: query
  })
}

//获取现烤订单订货详情
export function getOrderDetail (billId) {
  return request({
    url: '/api/system/shop/bill/freshly/baked/order/getDetail',
    method: 'get',
    params: { billId }
  })
}

// 添加现烤订单订货
export function addOrder (data) {
  return request({
    url: '/api/system/shop/bill/freshly/baked/order/save',
    method: 'post',
    data: data
  })
}

// 修改现烤订单订货
export function updateOrder (data) {
  return request({
    url: '/api/system/shop/bill/freshly/baked/order/update',
    method: 'put',
    data: data
  })
}

// 修改审核状态、关闭、删除
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/shop/bill/freshly/baked/order/updateBillStatus',
    method: 'POST',
    data
  })
}
