import request from '@/utils/request'

// 获取：商城信息详情
export function getMallSysSettings(params) {
  return request({
    url: '/api/system/mall/config/getMallSysSettings',
    method: 'get',
    params: params
  })
}
// 提交：商城信息
export function saveOrUpdateMallSysSettings(data) {
  return request({
    url: '/api/system/mall/config/saveOrUpdateMallSysSettings',
    method: 'post',
    data
  })
}
// 提交：直播
export function saveUpMallLiveBroadcastSettingsConfig(data) {
  return request({
    url: '/api/system/mall/config/saveUpMallLiveBroadcastSettingsConfig',
    method: 'post',
    data
  })
}
// 获取：直播
export function getMallLiveBroadcastSettingsDetail(params) {
  return request({
    url: '/api/system/mall/config/getMallLiveBroadcastSettingsDetail',
    method: 'get',
    params: params
  })
}
// 提交：运费
export function saveUpMallFreightSettingsConfig(data) {
  return request({
    url: '/api/system/mall/config/saveUpMallFreightSettingsConfig',
    method: 'post',
    data
  })
}
// 获取：运费配置详情
export function getMallFreightSettingsConfigDetail(params) {
  return request({
    url: '/api/system/mall/config/getMallFreightSettingsConfigDetail',
    method: 'get',
    params: params
  })
}
// 提交：售后
export function saveUpMallAfterSalesSettingsConfig(data) {
  return request({
    url: '/api/system/mall/config/saveUpMallAfterSalesSettingsConfig',
    method: 'post',
    data
  })
}
// 获取：售后
export function getMallAfterSalesSettingsConfigDetail(params) {
  return request({
    url: '/api/system/mall/config/getMallAfterSalesSettingsConfigDetail',
    method: 'get',
    params: params
  })
}
// 提交：交易
export function saveUpMallTransactionSettingsConfigDetail(data) {
  return request({
    url: '/api/system/mall/config/saveUpMallTransactionSettingsConfigDetail',
    method: 'post',
    data
  })
}
// 获取：交易
export function getMallTransactionSettingsConfigDetail(params) {
  return request({
    url: '/api/system/mall/config/getMallTransactionSettingsConfigDetail',
    method: 'get',
    params: params
  })
}
// 提交：商城设置
export function saveUpMallSystemConfig(data) {
  return request({
    url: '/api/system/mall/config/saveUpMallSystemConfig',
    method: 'post',
    data
  })
}
// 获取：商城设置
export function getSystemMallConfigDetail(params) {
  return request({
    url: '/api/system/mall/config/getSystemMallConfigDetail',
    method: 'get',
    params: params
  })
}

// 商城分析地址太阳码
export function mallAnalysisPage(data) {
  return request({
    url: '/api/system/mall/config/mallAnalysisPage',
    method: 'post',
    data
  })
}

// 商城分享链接
export function mallShare(data) {
  return request({
    url: '/api/system/mall/config/mallShare',
    method: 'post',
    data
  })
}
