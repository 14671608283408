import request from '@/utils/request'
//裱花订货报表      分页列表
export function shopGoodsListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花订货报表      合计行
export function shopGoodsListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//裱花原料订货报表   分页列表
export function materialOrderListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillMaterialOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花原料订货报表   合计行
export function materialOrderListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillMaterialOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花报废报表      分页列表
export function shopScrapListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillScrap',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花报废报表      合计行
export function shopScrapListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillScrapSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花调入报表      分页列表
export function ShopInListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillIn',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花调入报表      合计行
export function ShopInListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillInSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花调出报表      分页列表
export function ShopOutListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillOut',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花调出报表      合计行
export function ShopOutListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillOutSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花生产报表      分页列表
export function shopProduceListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillProduce',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花生产报表      合计行
export function shopProduceListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillProduceSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花领料报表      分页列表
export function shopPickingListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillPicking',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花领料报表      合计行
export function shopPickingListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillPickingSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 裱花盘点单查询      分页列表
export function shopCheckListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillCheck',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花盘点单查询      合计行
export function shopCheckListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/report/listBillCheckSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}













//裱花采购报表      分页列表
export function shopPurchaseListAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPurchase',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花采购报表      合计行
export function shopPurchaseListSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopPurchaseSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}





// 获取仓库调出分页列表
export function outStoreAPI(params) {
  return request({
    url: '/api/system/store/bill/out/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 裱花销售单、裱花销售订单      分页列表
export function listShopSaleOrderAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopSaleOrder',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花销售单、裱花销售订单      合计行
export function listShopSaleOrderSummaryAPI(params) {
  return request({
    url: '/api/system/shop/report/shop/bill/listShopSaleOrderSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
