import request from '@/utils/request'

// 加盟店配送退回收货单
export function franchiseHttpBillInList(params) {
  return request({
    url: '/api/system/franchise/store/bill/in/list',
    method: 'get',
    params
  })
}
