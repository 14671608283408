//导出url
export default {
  vipConsumeQuery:
    '/api/system/vip/report/shopping/query/listShoppingDetailExport', //会员购物报表
  vipFillQuery: '/api/system/vip/report/Fill/query/listFillDetailExport', //会员充值报表(充值)
  vipCashierQuery: '/api/system/vip/report/Fill/query/listCashierExport', //会员充值报表(收银员)
  vipScoreQuery: '/api/system/vip/report/score/query/listScoreDetailExport', //会员积分报表
  vipPayDetailQuery: '/api/system/vip/report/Pay/query/listPayDetailExport', //会员支付报表(明细)
  vipPaySummaryQuery: '/api/system/vip/report/Pay/query/listSummaryExport', //会员支付报表(汇总)
  vipCouponQuery: '/api/system/vip/report/coupon/query/listCouponDetailExport', //会员优惠券报表
  vipAnomalyQtyQuery: '/api/system/vip/report/abnormal/query/listPayQtyExport', //会员异常报表(次数)
  vipAnomalyMoneyQuery:
    '/api/system/vip/report/abnormal/query/listPayMoneyExport', //会员异常报表(金额)
  vipAnomalyScoreQuery:
    '/api/system/vip/report/abnormal/query/listScoreQtyExport', //会员异常报表(异常)
  vipEndValidQuery:
    '/api/system/vip/report/become/due/query/listBecomeDueDetailExport', //会员到期报表
  vipEmitQuery:
    '/api/system/vip/report/card/emit/query/listCardEmitDetailExport', //会员发卡记录报表
  vipExchangeGoodsQuery:
    '/api/system/vip/report/exchange/query/listExchangeGoodsExport', //会员兑换报表(商品)
  vipExchangeCouponQuery:
    '/api/system/vip/report/exchange/query/listExchangeCouponExport', //会员兑换报表(优惠券)
  vipExchangeMoneyQuery:
    '/api/system/vip/report/exchange/query/listExchangeMoneyExport', //会员兑换报表(金额)
  vipBirthdayQuery: '/api/system/vip/base/vipcard/getVipBirthdayExport', //会员生日报表
  vipLossQuery: '/api/system/vip/base/vipcard/getVipLossExport', //会员流失报表
  exportDetailSummaryForms:
    '/api/system/purchase/reportForms/exportDetailSummaryForms', //导出采购商品汇总
  exportSaleSummary: '/api/system/sale/reportForms/exportSaleSummary', //导出销售汇总表
  exportSaleOrderSummary: '/api/system/sale/reportForms/exportSaleOrderSummary', //导出销售订单汇总表
  exportOrder: '/api/system/mall/online/order/export', //商城订单列表导出
  exportSaleDetail: '/api/system/sale/reportForms/exportSaleDetail', //销售明细表导出
  passengerFlowDateShop: '/api/system/shop/base/passengerFlow/listExport', //客流量分析（按日期+分店）
  exportListStoreBookReport:
    '/api/system/store/report/book/exportListStoreBookReport', //仓库商品库存查询
  exportListStoreBookOutInDetail:
    '/api/system/store/report/book/exportListStoreBookOutInDetail', //仓库 商品出入库明细表
  exportListBillStatisticsReport:
    '/api/system/store/report/book/exportListBillStatisticsReport', //仓库 商品出入库单据
  exportListGoodStatisticsReport:
    '/api/system/store/report/book/exportListGoodStatisticsReport', //仓库 商品出入库汇总
  exportGoodsAvailableStock:
    '/api/system/store/reportForms/exportGoodsAvailableStock', //仓库 商品可用库存查询
  exportListBookStatisticsByStoreGoodsDay:
    '/api/system/store/statistics/exportListBookStatisticsByStoreGoodsDay', //仓库 商品每日库存查询
  exportListBookStatisticsByStoreGoods:
    '/api/system/store/statistics/exportListBookStatisticsByStoreGoods', //仓库 商品每日库存查询
  exportGoodsPurSaleStockList:
    '/api/system/store/report/book/exportGoodsPurSaleStockList', //仓库 商品进销存一览表
  exportTransferSummary: '/api/system/store/reportForms/exportTransferSummary', //仓库 仓库调拨汇总表
  exportTransferConfirm: '/api/system/store/reportForms/exportTransferConfirm', //仓库 仓库调拔确认表
  exportListTransferDetail:
    '/api/system/store/reportForms/exportListTransferDetail', //仓库 仓库调拨明细表
  exportDetailReportForms:
    '/api/system/purchase/reportForms/exportDetailReportForms', //仓库 导出采购明细表

  exportSummaryByStoreGoodsCategory:
    '/api/system/store/reportForms/exportSummaryByStoreGoodsCategory', //仓库+类别调拨汇总
  exportSummaryByStore: '/api/system/store/reportForms/exportSummaryByStore', //仓库 仓库调拨汇总表
  exportBookListExport: '/api/system/marketing/base/coupon/case/book/listExport', //仓库 仓库调拨汇总表

  exportArEarlyWarningReport: '/api/system/finance/early/warning/exportArEarlyWarningReport', //应收账款预警表
  exportApEarlyWarningReport: '/api/system/finance/early/warning/exportApEarlyWarningReport', //应付账款预警表
  exportCustomerCheckList: '/api/system/finance/check/exportCustomerCheckList', //客户对账单
  exportSupplierCheckList: '/api/system/finance/check/exportSupplierCheckList', //供应商对账单
  saleExportList: '/api/system/sale/bill/exportList', //销售订单导出
  shopOrderExportList: '/api/system/shop/bill/order/listExport', //门店订货
  shopMaterialOrderExportList: '/api/system/shop/bill/material/order/listExport', //门店原料订货列表
  shopPurchaseExportList: '/api/system/shop/bill/purchase/listExport', //门店采购列表
  shopSaleExportList: '/api/system/shop/bill/sale/listExport', //门店销售单
  shopScrapExportList: '/api/system/shop/bill/scrap/listExport', //门店报废列表
  shopInExportList: '/api/system/shop/bill/in/listExport', //门店调入列表
  shopOutExportList: '/api/system/shop/bill/out/listExport', //门店调出列表
  shopProduceExportList: '/api/system/shop/bill/produce/listExport', //门店生产列表
  shopPickingExportList: '/api/system/shop/bill/picking/listExport', //门店原料消耗列表
  shopCheckExportList: '/api/system/shop/bill/check/listExport', //门店盘点列表
  shopInventoryExportList: '/api/system/shop/bill/inventory/surplus/listExport', //门店盘点列表
  exportOrderList: '/api/system/shop/bill/preOrder/billOrderListCommonListExport', //预订单
  exportListBookStatisticsByStoreGoodsMonth: '/api/system/store/month/book/statistics/exportListBookStatisticsByStoreGoodsMonth', //预订单
  exportListMonthBookStatisticsByStoreGoods: '/api/system/store/month/book/statistics/exportListMonthBookStatisticsByStoreGoods', //预订单
  decorationListExport: '/api/system/decoration/bill/order/listExport', // 裱花半成品订货
  decorationMaterialListExport: '/api/system/decoration/bill/material/order/listExport', // 裱花原料订货
  decorationPickingListExport: '/api/system/decoration/bill/picking/listExport', // 裱花原料消耗
  decorationScrapListExport: '/api/system/decoration/bill/scrap/listExport', // 裱花报废
  decorationLnListExport: '/api/system/decoration/bill/in/listExport', // 裱花调入
  decorationOutListExport: '/api/system/decoration/bill/out/listExport', // 裱花调出
  decorationCheckListExport: '/api/system/decoration/bill/check/listExport', // 裱花盘点
  decorationSurplusListExport: '/api/system/decoration/bill/inventory/surplus/listExport', // 裱花盘盈
  decorationLossListExport: '/api/system/decoration/bill/inventory/loss/listExport', // 裱花盘亏

  freshlyListExport: '/api/system/freshly/baked/bill/order/listExport', // 现烤半成品订货
  freshlyMaterialListExport: '/api/system/freshly/baked/bill/material/order/listExport', // 现烤原料订货
  freshlyPickingListExport: '/api/system/freshly/baked/bill/picking/listExport', // 现烤原料消耗
  freshlyScrapListExport: '/api/system/freshly/baked/bill/scrap/listExport', // 现烤报废
  freshlyLnListExport: '/api/system/freshly/baked/bill/in/listExport', // 现烤调入
  freshlyOutListExport: '/api/system/freshly/baked/bill/out/listExport', // 现烤调出
  freshlyCheckListExport: '/api/system/freshly/baked/bill/check/listExport', // 现烤盘点
  freshlySurplusListExport: '/api/system/freshly/baked/bill/inventory/surplus/listExport', // 现烤盘盈
  freshlyLossListExport: '/api/system/freshly/baked/bill/inventory/loss/listExport', // 现烤盘亏
  freshlyOrderExport: '/api/system/freshly/baked/room/order/export', // 现烤订单制作
}
