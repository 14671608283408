import request from "@/utils/request";
//首页

//获取菜单列表
export function getMenuList(employeeId) {
  return request({
    url: '/api/system/shop/base/config/getRouters',
    method: 'get',
    params: {
      employeeId
    }
  })
}

// 新增/修改自定义快捷菜单
export function handleShortcutMenu(menuList) {
  return request({
    url: '/api/system/shop/base/config/saveOrUpdate',
    method: 'POST',
    data: menuList
  })
}

// 获取自定义快捷菜单列表
export function getShortcutMenuList(employeeId) {
  return request({
    url: '/api/system/shop/base/config/list',
    method: 'get',
    params: {
      employeeId
    }
  })
}


//业务列表数据
export function getHomeBusinessData() {
  return request({
    url: '/api/system/saas/homeReportForms/getHomeBusiness',
  })
}

//营业数据
// export function getHomeTradeData(query) {
//   return request({
//     url: '/api/system/saas/homeReportForms/trade',
//     params:query,
//     timeout: 0 // 设置超时时间为无限
//   })
// }



//营业订单趋势图
export function getHomeOrderTrendData(params) {
  return request({
    url: '/api/system/shop/overview/getOrderTrend',
    method: 'get',
    params
  })
}

//营业数据
export function getHomeTradeData(params) {
  return request({
    url: '/api/system/saas/homeReportForms/trade',
    params,
  })
}

//营业数据-生产
export function getHomeTradeProduceData(params) {
  return request({
    url: '/api/system/saas/homeReportForms/getProduceData',
    params,
  })
}

//营业数据-采购
export function getHomeTradePurData(params) {
  return request({
    url: '/api/system/saas/homeReportForms/getPurData',
    params,
  })
}

//营业数据-销售
export function getHomeTradeSaleData(params) {
  return request({
    url: '/api/system/saas/homeReportForms/getSaleData',
    params,
  })
}

//营业数据-仓库
export function getHomeTradeStoreData(params) {
  return request({
    url: '/api/system/saas/homeReportForms/getStoreData',
    params,
  })
}

//会员数据
export function getHomeVipData(query) {
  return request({
    url: '/api/system/saas/homeReportForms/vipData',
    params: query
  })
}

//销售排行数据
export function getHomeSaleRankingData(query) {
  return request({
    url: '/api/system/saas/homeReportForms/saleRanking',
    params: query
  })
}

//获取官方公告
export function getHomeOfficialNotice() {
  return request({
    url: `/api/system/saas/homeReportForms/officialNotice`,
  })
}

//获取官方公告详情
export function getHomeOfficialNoticeDetail(noticeId) {
  return request({
    url: `/api/system/saas/homeReportForms/officialNoticeDetail?noticeId=${noticeId}`,
  })
}

//获取企业公告
export function getHomeEnterpriseNotice() {
  return request({
    url: `/api/system/saas/homeReportForms/enterpriseNotice`,
  })
}

//获取企业公告详情
export function getHomeEnterpriseNoticeDetail(noticeId) {
  return request({
    url: `/api/system/saas/homeReportForms/enterpriseNoticeDetail?noticeId=${noticeId}`,
  })
}

//当月目标
export function getHomeCurrentMonthGoal() {
  return request({
    url: `/api/system/saas/homeReportForms/getCurrentMonthGoal`,
  })
}

//获取菜单列表
export function getReminderInformation() {
  return request({
    url: '/api/system/saas/homeReportForms/getReminderInformation',
    method: 'get',
  })
}