import request from '@/utils/request'

//门店商品日分析分页列表
export function shopGoodsDailyTotalList(params) {
  return request({
    url: '/api/system/shop/strategy/shopGoodsDayAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店商品日分析分页汇总
export function shopGoodsDailyTotalListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/shopGoodsDayAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
