import request from '@/utils/request'

//配送汇总分页列表
export function franchiseMoveQueryList(params) {
  return request({
    url: '/api/system/franchise/delivery/report/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送汇总分页 汇总
export function franchiseMoveQueryListSummary(params) {
  return request({
    url: '/api/system/franchise/delivery/report/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送明细分页列表
export function franchiseDetailMoveQueryList(params) {
  return request({
    url: '/api/system/franchise/delivery/report/listDetailPage',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送明细分页 汇总
export function franchiseDetailMoveQueryListSummary(params) {
  return request({
    url: '/api/system/franchise/delivery/report/listDetailPageSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
