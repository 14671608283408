import request from '@/utils/request'

//抖音产品分页列表
export function listDouYinProduct(params) {
  return request({
    url: '/api/system/o2othird/douyin/product/list',
    method: 'get',
    params
  })
}

//新增抖音产品
export function saveDouYinProduct(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/saveDouYinProduct',
    method: 'post',
    data
  })
}

//获取抖音产品详情
export function detailDouYinProduct(productId) {
  return request({
    url: '/api/system/o2othird/douyin/product/getDetail?productId=' + productId,
    method: 'get'
  })
}

//修改抖音产品
export function updateDouYinProduct(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/updateDouYinProduct',
    method: 'put',
    data
  })
}

//上传抖音商品
export function uploadDouYinProduct(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/uploadDouYinProduct',
    method: 'post',
    data
  })
}

//抖音商品上下架
export function productDouYinOperate(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/productOperate',
    method: 'post',
    data
  })
}

//删除抖音产品
export function productDouYinDelete(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/delete',
    method: 'delete',
    data
  })
}

//还原被删除产品
export function douYinProductRestore(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/revert',
    method: 'post',
    data
  })
}

//同步抖音劵SKU库存
export function stockSyncDouYinProduct(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/stockSync',
    method: 'post',
    data
  })
}

//查询线上抖音商品上架状态
export function getOnlineProduct(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/getOnlineProduct',
    method: 'post',
    data
  })
}

//获取抖音产品分类
export function getDouYinCategory() {
  return request({
    url: '/api/system/o2othird/douyin/product/category/getDouYinCategory',
    method: 'get',
  })
}
