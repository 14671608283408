import request from '@/utils/request'

//行政地区分类树
export function getRegionTree(query) {
  return request({
    url: '/api/system/common/region/listTree',
    method: 'get',
    params: query
  })
}



// 获取行政地区详情
export function getRegionDetail(regionId) {
  return request({
    url: '/api/system/common/region/getDetail',
    method: 'get',
    params: { regionId }
  })
}


// 新增下级请求
export function addRegion(data) {
  return request({
    url: '/api/system/common/region/save',
    method: 'post',
    data: data
  })
}

// 修改行政地区
export function updateRegion(data) {
  return request({
    url: '/api/system/common/region/update',
    method: 'put',
    data: data
  })
}

// 删除行政地区
export function delRegion(regionId) {
  return request({
    url: `/api/system/common/region/delete`,
    method: 'delete',
    data: { regionId }
  })
}


//获取全部行政地区
export function getAllRegion() {
  return request({
    timeout: 30000,
    url: `/api/system/common/region/getAllRegion`,
  })
}