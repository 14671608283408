import request from '@/utils/request'

// 素材分页列表
export function materialListAPI (params) {
  return request({
    url: '/api/system/mall/material/list',
    method: 'get',
    params
  })
}
// 新增素材
export function materialAddAPI (data) {
  return request({
    url: '/api/system/mall/material/save',
    method: 'post',
    data
  })
}
//获取素材详情
export function materialDetailAPI (materialId) {
  return request({
    url: '/api/system/mall/material/getDetail?materialId=' + materialId,
    method: 'get'
  })
}
// 修改素材
export function materialUpdateAPI (data) {
  return request({
    url: '/api/system/mall/material/update',
    method: 'put',
    data
  })
}
//删除素材
export function materialRemoveAPI (materialIds) {
  return request({
    url: '/api/system/mall/material/delete',
    method: 'delete',
    data: materialIds
  })
}
// 批量修改分组
export function materialBatchUpdateAPI (data) {
  return request({
    url: '/api/system/mall/material/batchUpdate',
    method: 'put',
    data
  })
}

// 要加入活动的商品列表
export function listActivityGoodsOption (params) {
    return request({
        url: '/api/system/mall/goods/info/listActivityGoodsOption',
        method: 'get',
        params
    })
}
