import request from '@/utils/request'

// 预订单明细查询
export function getOderList(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 预订单明细查询汇总
export function getOderListSummary(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 预订单查询
export function reportGetOrderInfo(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 预订单查询汇总
export function reportGetOrderInfoSummary(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花订单按日期查询汇总
export function reportGetOrderDateList(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderDateList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花订单按日期汇总
export function reportGetOrderDateListSummary(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderListDateSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花订单按门店查询汇总
export function reportGetOrderShopList(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderShopList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花订单按门店汇总
export function reportGetOrderShopListSummary(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderShopListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 裱花订单按商品查询汇总
export function reportGetOrderGoodsList(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderGoodsList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 裱花订单按门店汇总
export function reportGetOrderGoodsListSummary(params) {
  return request({
    url: '/api/system/decoration/order/report/getOrderGoodsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}


