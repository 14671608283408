import request from '@/utils/request'

// 商城分类同步按钮
export function syncGoodsCategory(params) {
    return request({
        url: '/api/system/mall/goods/category/syncGoodsCategory',
        method: 'post',
        params: params
    })
}
// 商品分类列表
export function categoryList(params) {
    return request({
        url: '/api/system/mall/goods/category/list',
        method: 'get',
        params: params
    })
}
// 商品分类分页列表
export function categoryPageList(params) {
    return request({
        url: '/api/system/mall/goods/category/listPage',
        method: 'get',
        params: params
    })
}
// 添加
export function categorySave(data) {
    return request({
        url: '/api/system/mall/goods/category/save',
        method: 'post',
        data
    })
}
// 修改分类
export function categoryUpdate(data) {
    return request({
        url: '/api/system/mall/goods/category/update',
        method: 'put',
        data
    })
}
// 上移下移
export function categoryUpdateSortNo(data) {
    return request({
        url: '/api/system/mall/goods/category/updateSortNo',
        method: 'post',
        data
    })
}
// 删除
export function categoryRemove(data) {
    return request({
        url: '/api/system/mall/goods/category/remove',
        method: 'post',
        data
    })
}

// 删除
export function categoryReduction(data) {
    return request({
        url: '/api/system/mall/goods/category/reduction',
        method: 'post',
        data
    })
}
