import request from '@/utils/request'

//类别销售排名分页列表
export function categorySaleList(params) {
  return request({
    url: '/api/system/shop/strategy/categorySale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//类别销售排名分页汇总
export function categorySaleListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/categorySale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
