import request from '@/utils/request'

//会员品质分析分页列表
export function vipQualityAnalysisList(params) {
  return request({
    url: '/api/system/shop/strategy/vipQualityAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}
