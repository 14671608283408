import request from '@/utils/request'

// 分页列表
export function inviteGiftList(params) {
    return request({
        url: `/api/system/mall/marketing/invite/gift/list`,
        method: 'GET',
        params
    })
}
// 分页列表明细
export function getMarketingInviteGiftLog(params) {
    return request({
        url: `/api/system/mall/marketing/invite/gift/getMarketingInviteGiftLog`,
        method: 'GET',
        params
    })
}
// 新增
export function inviteGiftSave (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/save',
        method: 'post',
        data
    })
}
// 复制
export function inviteGiftCopy (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/copyActivity',
        method: 'post',
        data
    })
}
// 详情
export function inviteGiftDetail(params) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/getDetail',
        method: 'get',
        params: params
    })
}
// 编辑
export function inviteGiftUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/update',
        method: 'put',
        data
    })
}
// 审核
export function inviteGiftAudit (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/audit',
        method: 'put',
        data
    })
}
// 启用/禁用
export function inviteGiftUpdateIsEnable (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/updateIsEnable',
        method: 'put',
        data
    })
}
// 删除
export function inviteGiftDelete (data) {
    return request({
        url: '/api/system/mall/marketing/invite/gift/delete',
        method: 'delete',
        data
    })
}