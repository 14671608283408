import request from '@/utils/request'

// 获取商户页面组件模板配置
export function getDetail(templateId) {
  return request({
    url: `/api/system/o2othird/cakefreeselect/template/getDetail?templateId=${templateId}`,
    method: 'get',
  })
}

// 新增或修改页面组件模板配置
export function saveOrUp(data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/template/saveOrUp',
    method: 'post',
    data
  })
}

// 新增或修改页面组件模板配置
export function templateList(data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/template/list',
    method: 'get',
    data
  })
}