import request from '@/utils/request'

//断货分析分页列表
export function sellOutList(params) {
  return request({
    url: '/api/system/shop/strategy/break/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//断货分析分页汇总
export function sellOutListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/break/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
