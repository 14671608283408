import request from '@/utils/request'

// 扫码点餐商品资料分页列表
export function listPage(params) {
  return request({
    url: '/api/system/o2othird/ordermeal/order/listPage',
    method: 'get',
    params
  })
}

// 获取扫码点餐后台订单明细
export function getOrderDetail(params) {
  return request({
    url: '/api/system/o2othird/ordermeal/order/getOrderDetail',
    method: 'get',
    params
  })
}
