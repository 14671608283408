import request from '@/utils/request'


//获取主商品列表
export function listMainGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/listMainGoods',
    method: 'POST',
    data
  })
}
//获取子商品列表
export function listSubGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/listSubGoods',
    method: 'POST',
    data
  })
}
//添加主商品
export function addMainGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/addMainGoods',
    method: 'POST',
    data
  })
}
//修改主商品
export function modifyMainGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/modifyMainGoods',
    method: 'POST',
    data
  })
}
//添加子商品
export function addSubGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/addSubGoods',
    method: 'POST',
    data
  })
}
//修改子商品
export function modifySubGoods(data) {
  return request({
    url: '/api/system/store/goods/relate/modifySubGoods',
    method: 'POST',
    data
  })
}
//删除主商品或删除子商品
export function delGoodsRelate(data) {
  return request({
    url: '/api/system/store/goods/relate/delGoodsRelate',
    method: 'POST',
    data
  })
}
//添加组装商品单
export function addAssembleGoodsBill(data) {
  return request({
    url: '/api/system/store/assemble/goods/addAssembleGoodsBill',
    method: 'POST',
    data
  })
}
//添加组装商品单
export function modifyAssembleGoodsBill(data) {
  return request({
    url: '/api/system/store/assemble/goods/modifyAssembleGoodsBill',
    method: 'POST',
    data
  })
}
//修改组装商品单状态
export function modifyBillStatus(data) {
  return request({
    url: '/api/system/store/assemble/goods/modifyBillStatus',
    method: 'POST',
    data
  })
}
//获取组装商品单列表
export function listAssembleGoodsBill(data) {
  return request({
    url: '/api/system/store/assemble/goods/listAssembleGoodsBill',
    method: 'POST',
    data
  })
}
//获取组装商品单列表
export function sumListAssembleGoodsBill(data) {
  return request({
    url: '/api/system/store/assemble/goods/sumListAssembleGoodsBill',
    method: 'POST',
    data
  })
}
// 获取组装商品单详情
export function getAssembleGoodsBillDetail(params) {
  return request({
    url: '/api/system/store/assemble/goods/getAssembleGoodsBillDetail',
    method: 'get',
    params: params
  })
}

export function updateCombinStatus(params) {
  return request({
    url: params.url,
    method: 'post',
    data: {
      billIds: params.billIds,
      billStatus: params.billStatus,
    }
  })
}

//获取商品拆卸单列表
export function listDisassembleGoodsBill(data) {
  return request({
    url: '/api/system/store/disassemble/goods/listDisassembleGoodsBill',
    method: 'POST',
    data
  })
}
//获取商品拆卸单列表合计
export function sumListDisassembleGoodsBill(data) {
  return request({
    url: '/api/system/store/disassemble/goods/sumListDisassembleGoodsBill',
    method: 'POST',
    data
  })
}
//添加商品拆卸单列表
export function addDisassembleGoodsBill(data) {
  return request({
    url: '/api/system/store/disassemble/goods/addDisassembleGoodsBill',
    method: 'POST',
    data
  })
}
//修改商品拆卸单列表
export function modifyDisassembleGoodsBill(data) {
  return request({
    url: '/api/system/store/disassemble/goods/modifyDisassembleGoodsBill',
    method: 'POST',
    data
  })
}
// 获取商品拆卸单详情
export function getDisassembleGoodsBillDetail(params) {
  return request({
    url: '/api/system/store/disassemble/goods/getDisassembleGoodsBillDetail',
    method: 'get',
    params: params
  })
}
//修改拆卸商品单状态
export function goodsModifyBillStatus(data) {
  return request({
    url: '/api/system/store/disassemble/goods/modifyBillStatus',
    method: 'POST',
    data
  })
}

//商品组装拆卸查询 - 组装明细查询 
export function listAssembleGoodsDetail(data) {
  return request({
    url: '/api/system/store/assemble/goods/listAssembleGoodsDetail',
    method: 'POST',
    data
  })
}
export function sumListAssembleGoodsDetail(data) {
  return request({
    url: '/api/system/store/assemble/goods/sumListAssembleGoodsDetail',
    method: 'POST',
    data
  })
}

//商品组装拆卸查询 - 组装汇总查询
export function listSummaryAssembleGoods(data) {
  return request({
    url: '/api/system/store/assemble/goods/listSummaryAssembleGoods',
    method: 'POST',
    data
  })
}
export function sumListSummaryAssembleGoods(data) {
  return request({
    url: '/api/system/store/assemble/goods/sumListSummaryAssembleGoods',
    method: 'POST',
    data
  })
}

//商品组装拆卸查询 - 拆卸明细查询
export function listDisassembleGoodsDetail(data) {
  return request({
    url: '/api/system/store/disassemble/goods/listDisassembleGoodsDetail',
    method: 'POST',
    data
  })
}
export function sumListDisassembleGoodsDetail(data) {
  return request({
    url: '/api/system/store/disassemble/goods/sumListDisassembleGoodsDetail',
    method: 'POST',
    data
  })
}

//商品组装拆卸查询 - 拆卸汇总查询
export function listSummaryDisassembleGoods(data) {
  return request({
    url: '/api/system/store/disassemble/goods/listSummaryDisassembleGoods',
    method: 'POST',
    data
  })
}
export function sumListSummaryDisassembleGoods(data) {
  return request({
    url: '/api/system/store/disassemble/goods/sumListSummaryDisassembleGoods',
    method: 'POST',
    data
  })
}
