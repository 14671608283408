import request from '@/utils/request'

//会员门店经营分析分页列表
export function vipShopAnalysisList(params) {
  return request({
    url: '/api/system/shop/strategy/vipShopOperate/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员门店经营分析列表(合计汇总)
export function vipShopAnalysisListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/vipShopOperate/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
