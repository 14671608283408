import request from '@/utils/request'

// 商品分类树目录
export function listCategoryTree (query) {
  return request({
    url: '/api/system/goods/category/listTree',
    method: 'get',
    params: query
  })
}

// 商品分类列表
export function listCategory (query) {
  return request({
    url: '/api/system/goods/category/list',
    method: 'get',
    params: query
  })
}

// 新增商品分类
export function addCategory (data) {
  return request({
    url: '/api/system/goods/category/save',
    method: 'post',
    data: data
  })
}

// 修改商品分类
export function updateCategory (data) {
  return request({
    url: '/api/system/goods/category/update',
    method: 'put',
    data
  })
}

// 删除商品分类
export function delCategory (categoryIds) {
  return request({
    url: '/api/system/goods/category/remove',
    method: 'delete',
    data: categoryIds
  })
}

export function CategoryDetail (id) {
  return request({
    url: `/api/system/goods/category/getDetail`,
    params: {
      categoryId: id
    }
  })
}

// 获取商品分类树
export function getGoodsCategoryTree () {
  return request({
    url: '/api/system/goods/category/getGoodsCategoryTree',
    method: 'get'
  })
}

// 获取编码号(添加商品)
export function sysNoRuleGetCodeNo (data) {
  return request({
    url: '/api/system/sysNoRule/getCodeNo',
    method: 'post',
    data
  })
}

// 还原商品类别
export function restoreGoodsCategory (categoryIds) {
  return request({
    url: '/api/system/goods/category/restoreGoodsCategory',
    method: 'POST',
    data: { categoryIds }
  })
}
//商品分类编号
export function getGoodsCategoryAutoNo (categoryId) {
  return request({
    url:
      '/api/system/goods/category/getGoodsCategoryAutoNo?categoryId=' +
      categoryId,
    method: 'get'
  })
}
// 导入
export function uploadFileRequest (data) {
  return request({
    url: '/api/system/goods/info/importGoodsInfo',
    method: 'POST',
    timeout: 300000, // 设置超时时间为5min
    data
  })
}
