import request from '@/utils/request'

//更新现烤盘盈单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/freshly/baked/bill/inventory/surplus/updateBillStatus',
    method: 'post',
    data
  })
}

// 现烤盘盈单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/bill/inventory/surplus/list',
    method: 'get',
    params: query
  })
}

//获取现烤盘盈单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/freshly/baked/bill/inventory/surplus/getDetail?billId=${billId}`
  })
}

//新增现烤盘盈单据
export function addOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/inventory/surplus/save',
    method: 'POST',
    data
  })
}

//修改现烤盘盈单据
export function updateOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/inventory/surplus/update',
    method: 'PUT',
    data
  })
}
