import request from '@/utils/request'

//客供区域分类树
export function getAreaTree (query) {
  return request({
    url: '/api/system/partner/area/listTree',
    method: 'get',
    params: query
  })
}

// 客供区域列表
export function listArea (query) {
  return request({
    url: '/api/system/partner/area/list',
    method: 'get',
    params: query
  })
}

//获取客供区域详情
export function getAreaDetail (areaId) {
  return request({
    url: '/api/system/partner/area/getDetail',
    method: 'get',
    params: { areaId }
  })
}

// 添加客供区域
export function addArea (data) {
  return request({
    url: '/api/system/partner/area/save',
    method: 'post',
    data: data
  })
}

// 修改客供区域
export function updateArea (data) {
  return request({
    url: '/api/system/partner/area/update',
    method: 'put',
    data: data
  })
}

// 删除客供区域
export function delArea (areaIds) {
  return request({
    url: `/api/system/partner/area/remove`,
    method: 'delete',
    data: areaIds
  })
}

// 还原客供区
export function restorePartnerArea (areaIds) {
  return request({
    url: '/api/system/partner/area/restorePartnerArea',
    method: 'POST',
    data: { areaIds }
  })
}
