import request from '@/utils/request'

// 加盟门店原料订货
export function franchiseListOrder(params) {
  return request({
    url: '/api/system/franchise/bill/material/order/list',
    method: 'get',
    params
  })
}

//获取加盟门店原料订货详情
export function franchiseGetOrderDetail(billId) {
  return request({
    url: '/api/system/franchise/bill/material/order/getDetail',
    method: 'get',
    params: { billId }
  })
}

// 添加加盟门店原料订货
export function franchiseAddOrder(data) {
  return request({
    url: '/api/system/franchise/bill/material/order/save',
    method: 'post',
    data
  })
}

// 修改加盟门店原料订货
export function franchiseUpdateOrder(data) {
  return request({
    url: '/api/system/franchise/bill/material/order/update',
    method: 'put',
    data
  })
}

// 修改审核状态、关闭、删除
export function franchiseUpdateOrderStatus(data) {
  return request({
    url: '/api/system/franchise/bill/material/order/updateBillStatus',
    method: 'POST',
    data
  })
}
