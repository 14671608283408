import request from '@/utils/request'

//配送毛利分页列表
export function franchiseShopSaleList(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopSale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送商品毛利分页列表
export function franchiseShopSaleDetailList(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopSale/ranking/listDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送毛利分页 汇总
export function franchiseListSummary(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopSale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//配送毛利分页明细 汇总
export function franchiseListDetailSummary(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopSale/ranking/listDetailSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}