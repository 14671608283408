import request from '@/utils/request'

//
export const getOrderDetail = params =>
  request({
    url: '/api/system/freshly/baked/base/man/getOrderDetail',
    method: 'get',
    params
  })

// 生成订单(需要先生成订单后再去调用支付二维码)
export const generateOrder = data =>
  request({
    url: '/api/system/freshly/baked/base/man/generateOrder',
    method: 'post',
    data
  })

export const orderPay = data =>
  request({
    url: '/api/system/freshly/baked/base/man/orderPay',
    method: 'post',
    data
  })

export const getManList = params =>
  request({
    url: '/api/system/freshly/baked/base/man/list',
    method: 'GET',
    params
  })

export const getOrderState = params =>
  request({
    url: '/api/system/freshly/baked/base/man/getOrderState',
    method: 'GET',
    params
  })

export const save = data =>
  request({ url: '/api/system/freshly/baked/base/man/save', method: 'POST', data })

export const remove = data =>
  request({
    url: '/api/system/freshly/baked/base/man/remove',
    method: 'DELETE',
    data
  })

export const update = data =>
  request({
    url: '/api/system/freshly/baked/base/man/update',
    method: 'PUT',
    data
  })

// 现烤间分页列表
export const getFreshlyBakedRoomList = params =>
  request({ url: '/api/system/freshly/baked/case/list', method: 'get', params })

// 获取现烤间详情
export const getRoomDetail = params =>
  request({
    url: '/api/system/freshly/baked/room/getDetai',
    method: 'get',
    params
  })

// 删除现烤间
export const delRoom = data =>
  request({
    url: `/api/system/freshly/baked/room/delete/${data}`,
    method: 'DELETE'
  })

// 新增现烤间
export const saveRoom = data =>
  request({ url: '/api/system/freshly/baked/room/save', method: 'post', data })

// 修改现烤间
export const updateRoom = data =>
  request({ url: '/api/system/freshly/baked/room/update', method: 'PUT', data })

// 导出现烤间分页列表
export const exportRoom = data =>
  request({ url: '/api/system/freshly/baked/room/export', method: 'post', data })

// 现烤商品方案分页列表
export const getCaseList = params =>
  request({ url: '/api/system/freshly/baked/case/list', method: 'get', params })

// 获取现烤间商品方案详情
export const getCaseDetail = params =>
  request({
    url: '/api/system/freshly/baked/case/getDetail',
    method: 'get',
    params
  })
export function getUserCopy(data) {
  return request({
    url: '/api/system/freshly/baked/case/copy',
    method: 'post',
    data
  })
}
// 是否停用现烤商品方案
export const isStopCase = data =>
  request({ url: '/api/system/freshly/baked/case/isStop', method: 'post', data })

// 删除现烤商品方案
export const delCase = data =>
  request({
    url: `/api/system/freshly/baked/case/delete/${data}`,
    method: 'DELETE'
  })

// 新增现烤商品方案
export const saveCase = data =>
  request({ url: '/api/system/freshly/baked/case/save', method: 'post', data })

// 修改现烤商品方案
export const updateCase = data =>
  request({ url: '/api/system/freshly/baked/case/update', method: 'PUT', data })

// 现烤现烤间订单分页列表
export const getRoomOrder = params =>
  request({
    url: '/api/system/freshly/baked/room/order/list',
    method: 'get',
    params
  })

// 获取现烤间订单方案详情
export const getRoomOrderDetail = params =>
  request({
    url: '/api/system/freshly/baked/room/order/getDetail',
    method: 'get',
    params
  })

// 导出现烤间订单
export const exportRoomOrder = data =>
  request({
    url: '/api/system/freshly/baked/room/order/export',
    method: 'post',
    data
  })

// 删除现烤间订单
export const delRoomOrder = data =>
  request({
    url: `/api/system/freshly/baked/room/order/delete/${data}`,
    method: 'DELETE'
  })

// 新增现烤间订单
export const saveRoomOrder = data =>
  request({
    url: '/api/system/freshly/baked/room/order/save',
    method: 'post',
    data
  })

// 修改现烤间订单
export const updateRoomOrder = data =>
  request({
    url: '/api/system/freshly/baked/room/order/update',
    method: 'PUT',
    data
  })

// 通过现烤间编号获取门店原料消耗分页列表
export const listStoreBillTranQuery = params =>
  request({
    url: '/api/system/freshly/baked/room/order/listStoreBillTranQuery',
    method: 'GET',
    params
  })

// 将订单调至指定门店
export const deliveryShopUpdate = data =>
  request({
    url: '/api/system/freshly/baked/room/delivery/deliveryShopUpdate',
    method: 'post',
    data
  })

// 获取订单详情(指定订单详情商品)
export const getOrderMiddleFroundOneInfo = params =>
  request({
    url: '/api/system/freshly/baked/room/order/getOrderMiddleFroundOneInfo',
    method: 'GET',
    params
  })

// 将订单配送
export const deliveryShopOrder = data =>
  request({
    url: '/api/system/freshly/baked/room/delivery/deliveryShopOrder',
    method: 'post',
    data
  })

// 根据门店 id 获取对应仓库
export const getShopAllStore = params =>
  request({
    url: '/api/system/freshly/baked/room/delivery/getAllStore',
    method: 'get',
    params
  })

// 将订单改为制作中的订单
export const orderDoing = data =>
  request({
    url: '/api/system/freshly/baked/room/delivery/deliveryShopProductionOrderUpdate',
    method: 'post',
    data
  })

// 制作中
export const orderDone = data =>
  request({
    url: '/api/system/freshly/baked/room/delivery/deliveryShopProductionCompleteOrderUpdate',
    method: 'post',
    data
  })

// 打印
export const printOrder = data =>
  request({
    url: '/api/system/freshly/baked/room/delivery/deliveryShopPrintOrder',
    method: 'post',
    data
  })

export const getPackageList = params =>
  request({ url: '/api/system/freshly/baked/package/list', method: 'get', params })

export const getFreshlyList = params =>
  request({ url: '/api/system/freshly/baked/package/list', method: 'get', params })

// 现烤间生产分页列表
export const getProduceList = params =>
  request({
    url: '/api/system/freshly/baked/bill/produce/list',
    method: 'GET',
    params
  })

// 现烤间生产分页详情
export const getProduceDetail = params =>
  request({
    url: '/api/system/freshly/baked/bill/produce/getDetail',
    method: 'GET',
    params
  })

// 更新现烤间生产状态
export const updateBillStatus = data =>
  request({
    url: '/api/system/freshly/baked/bill/produce/updateBillStatus',
    method: 'POST',
    data
  })

// 新增现烤生产
export const addOrder = data =>
  request({
    url: '/api/system/freshly/baked/bill/produce/save',
    method: 'POST',
    data
  })

// 修改现烤生产
export const updateOrder = data =>
  request({
    url: '/api/system/freshly/baked/bill/produce/update',
    method: 'PUT',
    data
  })

// 现烤间产品续费
export const tradeProductRenew = data =>
  request({
    url: '/api/system/saas/freshly/baked/product/freshly/bakedProductRenew',
    method: 'post',
    data
  })

// 获取要续费的现烤间产品
export const getTradeProductToRenew = data =>
  request({
    url: '/api/system/saas/freshly/baked/product/getDecorationToRenew',
    method: 'post',
    data
  })

// 现烤间产品订单分页列表
export const getTradeProductOrder = data =>
  request({
    url: '/api/system/saas/freshly/baked/product/listDecorationOrder',
    method: 'get',
    data
  })
