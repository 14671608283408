import request from '@/utils/request'
// 应付采购款
export function getShouldPaymentPurchaseFunds(params) {
  return request({
    url: '/api/system/purchase/fund/getShouldPaymentPurchaseFunds',
    method: 'get',
    params: params
  })
}
// 应收销售款
export function getShouldReceiveSaleFunds(params) {
  return request({
    url: '/api/system/sale/fund/getShouldReceiveSaleFunds',
    method: 'get',
    params: params
  })
}
// ===============收款单=================

//获取原单
export function listBill(data) {
  return request({
    url: '/api/system/store/tran/listBill',
    method: 'POST',
    data
  })
}
// 收款单列表
export function listReceiveBill(data) {
  return request({
    url: '/api/system/finance/receive/bill/listReceiveBill',
    method: 'POST',
    data
  })
}
// 新增收款单
export function addReceiveBill(data) {
  return request({
    url: '/api/system/finance/receive/bill/addReceiveBill',
    method: 'POST',
    data
  })
}
// 修改收款单
export function modifyReceiveBill(data) {
  return request({
    url: '/api/system/finance/receive/bill/modifyReceiveBill',
    method: 'POST',
    data
  })
}
// 收款单详情
export function getReceiveBillByBillId(params) {
  return request({
    url: '/api/system/finance/receive/bill/getReceiveBillByBillId',
    method: 'get',
    params: params
  })
}
//更新单据状态
export function updateArbillStatus(params) {
  return request({
    url: params.url,
    method: 'post',
    data: {
      billIds: params.billIds,
      billStatus: params.billStatus,
      billType: params.billType,
    }
  })
}
//更新对账状态
export function updateReconStatus(params) {
  console.log(params, '444');
  return request({
    url: params.url,
    method: 'post',
    data: {
      billIds: params.billIds,
      checkAccountStatus: params.checkAccountStatus,
    }
  })
}

export function modifyBillStatus(data) {
  return request({
      url: '/api/system/finance/receive/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
// ===============收款退款单=================
// 收款退款单列表
export function listReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/receive/refund/bill/listReceiveRefundBill',
      method: 'post',
      data: data
  })
}

export function refundModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/receive/refund/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
export function addReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/receive/refund/bill/addReceiveRefundBill',
      method: 'post',
      data: data
  })
}
export function modifyReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/receive/refund/bill/modifyReceiveRefundBill',
      method: 'post',
      data: data
  })
}
export function listSourceReceiveBill(data) {
  return request({
      url: '/api/system/finance/receive/bill/listSourceReceiveBill',
      method: 'post',
      data: data
  })
}
export function getReceiveRefundBillByBillId(params) {
  return request({
    url: '/api/system/finance/receive/refund/bill/getReceiveRefundBillByBillId',
    method: 'get',
    params: params
  })
}
// ===============预收款单=================
// 预收款单列表
export function listPreReceiveBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/bill/listPreReceiveBill',
      method: 'post',
      data: data
  })
}
// 修改预收款单状态
export function receiveModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/pre/receive/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
//添加预收款单
export function addPreReceiveBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/bill/addPreReceiveBill',
      method: 'post',
      data: data
  })
}
//修改预收款单
export function modifyPreReceiveBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/bill/modifyPreReceiveBill',
      method: 'post',
      data: data
  })
}
// 获取预收款单信息
export function getPreReceiveBillByBillId(params) {
  return request({
    url: '/api/system/finance/pre/receive/bill/getPreReceiveBillByBillId',
    method: 'get',
    params: params
  })
}
// 选择销售单
export function preArSourceList(data) {
  return request({
      url: '/api/system/sale/bill/preArSourceList',
      method: 'post',
      data: data
  })
}

//  ===================预收款退款单 ========
// 预收款退款单列表
export function listPreReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/refund/bill/listPreReceiveRefundBill',
      method: 'post',
      data: data
  })
}
// 修改预收款退款单状态
export function receiveRefundModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/pre/receive/refund/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
// 添加预收款退款单
export function addPreReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/refund/bill/addPreReceiveRefundBill',
      method: 'post',
      data: data
  })
}
// 修改预收款退款单
export function modifyPreReceiveRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/receive/refund/bill/modifyPreReceiveRefundBill',
      method: 'post',
      data: data
  })
}

// 获取预收款退款单信息
export function getPreReceiveRefundBillByBillId(params) {
  return request({
    url: '/api/system/finance/pre/receive/refund/bill/getPreReceiveRefundBillByBillId',
    method: 'get',
    params: params
  })
}
// 源单选择预收款单
export function receiveListSourceReceiveBill(data) {
  return request({
    url: '/api/system/finance/pre/receive/bill/listSourceReceiveBill',
    method: 'post',
    data: data
  })
}


//  ===================付款单 ========
// 付款单列表
export function listPaymentBill(data) {
  return request({
      url: '/api/system/finance/payment/bill/listPaymentBill',
      method: 'post',
      data: data
  })
}

// 添加付款单
export function paymentBillAddPaymentBill(data) {
  return request({
      url: '/api/system/finance/payment/bill/addPaymentBill',
      method: 'post',
      data: data
  })
}

// 修改付款单
export function paymentBillModifyPaymentBill(data) {
  return request({
      url: '/api/system/finance/payment/bill/modifyPaymentBill',
      method: 'post',
      data: data
  })
}

// 修改付款单状态
export function paymentBillModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/payment/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}

// 获取付款单信息
export function paymentBillGetPaymentBillByBillId(params) {
  return request({
    url: '/api/system/finance/payment/bill/getPaymentBillByBillId',
    method: 'get',
    params: params
  })
}
//  =========================// 付款退款单列表 =============
// 付款退款单列表
export function listPaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/payment/refund/bill/listPaymentRefundBill',
      method: 'post',
      data: data
  })
}

// 修改付款退款单状态
export function paymentRefundModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/payment/refund/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
// 获取源--付款单
export function listSourcePaymentBill(data) {
  return request({
      url: '/api/system/finance/payment/bill/listSourcePaymentBill',
      method: 'post',
      data: data
  })
}
// 添加付款退款单
export function addPaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/payment/refund/bill/addPaymentRefundBill',
      method: 'post',
      data: data
  })
}
// 修改付款退款单
export function modifyPaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/payment/refund/bill/modifyPaymentRefundBill',
      method: 'post',
      data: data
  })
}
// 获取付款退款单信息
export function getPaymentRefundBillByBillId(params) {
  return request({
    url: '/api/system/finance/payment/refund/bill/getPaymentRefundBillByBillId',
    method: 'get',
    params: params
  })
}

//  =========================// 预付款单 =============
// 预付款单列表
export function listPrePaymentBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/bill/listPrePaymentBill',
      method: 'post',
      data: data
  })
}
// 预付款单修改状态
export function financePaymentBillModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/pre/payment/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
// 预付款单查询采购订单
export function purchaseBillPreApSourceList(data) {
  return request({
      url: '/api/system/purchase/bill/preApSourceList',
      method: 'post',
      data: data
  })
}
// 添加预付款单
export function paymentBillAddPrePaymentBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/bill/addPrePaymentBill',
      method: 'post',
      data: data
  })
}
// 修改预付款单
export function paymentBillModifyPrePaymentBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/bill/modifyPrePaymentBill',
      method: 'post',
      data: data
  })
}
// 获取付款退款单信息
export function getPrePaymentBillByBillId(params) {
  return request({
    url: '/api/system/finance/pre/payment/bill/getPrePaymentBillByBillId',
    method: 'get',
    params: params
  })
}

//  =========================// 预付款退款单列表 =============
// 预付款退款单列表
export function listPrePaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/refund/bill/listPrePaymentRefundBill',
      method: 'post',
      data: data
  })
}
// 预付款退款单选择源单--预付款
export function paymentListSourcePaymentBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/bill/listSourcePaymentBill',
      method: 'post',
      data: data
  })
}
// 添加预付款退款单
export function addPrePaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/refund/bill/addPrePaymentRefundBill',
      method: 'post',
      data: data
  })
}
// 修改预付款退款单
export function modifyPrePaymentRefundBill(data) {
  return request({
      url: '/api/system/finance/pre/payment/refund/bill/modifyPrePaymentRefundBill',
      method: 'post',
      data: data
  })
}
// 修改预付款退款单状态
export function prePaymentBillModifyBillStatus(data) {
  return request({
      url: '/api/system/finance/pre/payment/refund/bill/modifyBillStatus',
      method: 'post',
      data: data
  })
}
// 获取付款退款单信息
export function getPrePaymentRefundBillByBillId(params) {
  return request({
    url: '/api/system/finance/pre/payment/refund/bill/getPrePaymentRefundBillByBillId',
    method: 'get',
    params: params
  })
}

// 应收账款明细表
export function listShouldReceiveFundDetail(data) {
  return request({
    url: '/api/system/finance/book/detail/listShouldReceiveFundDetail',
    method: 'post',
    data: data
  })
}
// 应付账款明细表
export function listShouldPaymentFundDetail(data) {
  return request({
    url: '/api/system/finance/book/detail/listShouldPaymentFundDetail',
    method: 'post',
    data: data
  })
}
// 应收账款汇总表
export function listShouldReceiveFundStatistics(data) {
  return request({
    url: '/api/system/finance/book/detail/listShouldReceiveFundStatistics',
    method: 'post',
    data: data
  })
}
// 应付账款汇总表
export function listShouldPaymentFundStatistics(data) {
  return request({
    url: '/api/system/finance/book/detail/listShouldPaymentFundStatistics',
    method: 'post',
    data: data
  })
}

// ============ 手动核销
// 添加手动核销单
export function addManualSettleBill(data) {
  return request({
    url: '/api/system/finance/manual/settle/bill/addManualSettleBill',
    method: 'post',
    data: data
  })
}
// 修改手动核销单
export function manualSettleModifyManualSettleBill(data) {
  return request({
    url: '/api/system/finance/manual/settle/bill/modifyManualSettleBill',
    method: 'post',
    data: data
  })
}
// 修改手动核销单状态
export function manualSettleModifyBillStatus(data) {
  return request({
    url: '/api/system/finance/manual/settle/bill/modifyBillStatus',
    method: 'post',
    data: data
  })
}
// 手动核销单列表
export function listManualSettleBills(data) {
  return request({
    url: '/api/system/finance/manual/settle/bill/listManualSettleBill',
    method: 'post',
    data: data
  })
}
// 获取手动核销单信息
export function getManualSettleBillByBillId(params) {
  return request({
    url: '/api/system/finance/manual/settle/bill/getManualSettleBillByBillId',
    method: 'get',
    params: params
  })
}

// 期初应收  提供其他单据选择期初应收单源单列表
export function listSourceInitialPartnerArBill(data) {
  return request({
    url: '/api/system/finance/initial/partner/ar/listSourceInitialPartnerArBill',
    method: 'post',
    data: data
  })
}

// 期初应付  提供其他单据选择期初应付单源单列表
export function listSourceInitialPartnerApBill(data) {
  return request({
    url: '/api/system/finance/initial/partner/ap/listSourceInitialPartnerApBill',
    method: 'post',
    data: data
  })
}
// 其他收入 ----------------------------------------------------------
// 其他收入列表
export function otherListBill(data) {
  return request({
    url: '/api/system/finance/other/receive/bill/listBill',
    method: 'POST',
    data
  })
}

// 新增收入列表
export function otherReceiveAddBill(data) {
  return request({
    url: '/api/system/finance/other/receive/bill/addBill',
    method: 'POST',
    data
  })
}

// 获取其他收入单信息
export function otherReceiveGetBillByBillId(params) {
  return request({
    url: '/api/system/finance/other/receive/bill/getBillByBillId',
    method: 'get',
    params: params
  })
}
// 修改其他收入单信息
export function otherReceiveModifyBill(data) {
  return request({
    url: '/api/system/finance/other/receive/bill/modifyBill',
    method: 'POST',
    data
  })
}
// 修改其他收入单状态
export function otherReceiveModifyBillStatus(data) {
  return request({
    url: '/api/system/finance/other/receive/bill/modifyBillStatus',
    method: 'POST',
    data
  })
}


// 其他收入退款 ----------------------------------------------------------
// 其他收入列表退款
export function otherReceiveRefundListBill(data) {
  return request({
    url: '/api/system/finance/other/receive/refund/bill/listBill',
    method: 'POST',
    data
  })
}

// 新增收入列表退款
export function otherReceiveRefundAddBill(data) {
  return request({
    url: '/api/system/finance/other/receive/refund/bill/addBill',
    method: 'POST',
    data
  })
}

// 获取其他收入单信息退款
export function otherReceiveRefundGetBillByBillId(params) {
  return request({
    url: '/api/system/finance/other/receive/refund/bill/getBillByBillId',
    method: 'get',
    params: params
  })
}
// 修改其他收入单信息退款
export function otherReceiveRefundModifyBill(data) {
  return request({
    url: '/api/system/finance/other/receive/refund/bill/modifyBill',
    method: 'POST',
    data
  })
}
// 修改其他收入单状态退款
export function otherReceiveRefundModifyBillStatus(data) {
  return request({
    url: '/api/system/finance/other/receive/refund/bill/modifyBillStatus',
    method: 'POST',
    data
  })
}

// 其他支出 ----------------------------------------------------------
// 其他支出列表
export function otherPaymentListBill(data) {
  return request({
    url: '/api/system/finance/other/payment/bill/listBill',
    method: 'POST',
    data
  })
}

// 新增其他支出
export function otherPaymentAddBill(data) {
  return request({
    url: '/api/system/finance/other/payment/bill/addBill',
    method: 'POST',
    data
  })
}

// 获取其他支出
export function otherPaymentGetBillByBillId(params) {
  return request({
    url: '/api/system/finance/other/payment/bill/getBillByBillId',
    method: 'get',
    params: params
  })
}
// 修改其他支出
export function otherPaymentModifyBill(data) {
  return request({
    url: '/api/system/finance/other/payment/bill/modifyBill',
    method: 'POST',
    data
  })
}
// 修改其他支出状态
export function otherPaymentModifyCheckAccountStatus(data) {
  return request({
    url: '/api/system/finance/other/payment/bill/modifyCheckAccountStatus',
    method: 'POST',
    data
  })
}



// 其他支出退款 ----------------------------------------------------------
// 其他支出列表退款 
export function otherReceivePaymentListBill(data) {
  return request({
    url: '/api/system/finance/other/payment/refund/bill/listBill',
    method: 'POST',
    data
  })
}

// 新增其他支出退款 
export function otherRefundPaymentAddBill(data) {
  return request({
    url: '/api/system/finance/other/payment/refund/bill/addBill',
    method: 'POST',
    data
  })
}

// 获取其他支出退款 
export function otherRefundPaymentGetBillByBillId(params) {
  return request({
    url: '/api/system/finance/other/payment/refund/bill/getBillByBillId',
    method: 'get',
    params: params
  })
}

// 修改其他支出退款 
export function otherRefundPaymentModifyBill(data) {
  return request({
    url: '/api/system/finance/other/payment/refund/bill/modifyBill',
    method: 'POST',
    data
  })
}
// 修改其他支出状态退款 
export function otherPaymentRefundModifyBillStatus(data) {
  return request({
    url: '/api/system/finance/other/payment/refund/bill/modifyBillStatus',
    method: 'POST',
    data
  })
}

// 应收预警表 
export function financeWarningArEarlyWarningReport(data) {
  return request({
    url: '/api/system/finance/early/warning/arEarlyWarningReport',
    method: 'POST',
    data
  })
}
// 应收预警表合计
export function financeWarningSumArEarlyWarningReport(data) {
  return request({
    url: '/api/system/finance/early/warning/sumArEarlyWarningReport',
    method: 'POST',
    data
  })
}

// 应付预警表 
export function financeWarningApEarlyWarningReport(data) {
  return request({
    url: '/api/system/finance/early/warning/apEarlyWarningReport',
    method: 'POST',
    data
  })
}
// 应付预警表 合计
export function financeWarningSumApEarlyWarningReport(data) {
  return request({
    url: '/api/system/finance/early/warning/sumApEarlyWarningReport',
    method: 'POST',
    data
  })
}
// 客户对账列表
export function financeCheckCustomerCheckList(data) {
  return request({
    url: '/api/system/finance/check/customerCheckList',
    method: 'POST',
    data
  })
}
// 客户对账列表 合计
export function sumCustomerCheckList(data) {
  return request({
    url: '/api/system/finance/check/sumCustomerCheckList',
    method: 'POST',
    data
  })
}

// 供应商对账列表
export function financeCheckSupplierCheckList(data) {
  return request({
    url: '/api/system/finance/check/supplierCheckList',
    method: 'POST',
    data
  })
}
// 供应商对账列表 合计
export function financeChecksumSupplierCheckList(data) {
  return request({
    url: '/api/system/finance/check/sumSupplierCheckList',
    method: 'POST',
    data
  })
}
