import request from '@/utils/request'

//品牌销售排名分页列表
export function brandSaleList(params) {
  return request({
    url: '/api/system/shop/strategy/brandSale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//品牌销售排名分页汇总
export function brandSaleListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/brandSale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
