import request from '@/utils/request'

// 详情
export const getDrawList = (params) => request({
    url: '/api/system/mall/marketing/luck/draw/list',
    method: 'get',
    params
})

export const saveDraw = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/save',
    method: 'post',
    data
})

export const updateDraw = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/update',
    method: 'PUT',
    data
})

export const getDrawDetail = (params) => request({
    url: '/api/system/mall/marketing/luck/draw/getDetail',
    method: 'get',
    params
})

export const deleteDraw = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/delete',
    method: 'delete',
    data
})

export const updateIsEnable = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/updateIsEnable',
    method: 'PUT',
    data
})

export const auditDraw = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/audit',
    method: 'PUT',
    data
})

export const copyActivity = (data) => request({
    url: '/api/system/mall/marketing/luck/draw/copyActivity',
    method: 'POST',
    data
})

