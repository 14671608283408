import request from '@/utils/request'

//销售月汇总分页列表
export function saleMonthTotalList(params) {
  return request({
    url: '/api/system/shop/strategy/saleMonth/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售月汇总分页 汇总
export function saleMonthTotalListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/saleMonth/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
