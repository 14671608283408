import request from '@/utils/request'

// 扫码点餐商品资料分页列表
export function goodsList (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/list',
    method: 'get',
    params
  })
}

// 扫码点餐商品资料详情
export function getOrdermealGoodsDetail (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/getOrdermealGoodsDetail',
    method: 'get',
    params
  })
}

// 修改扫码点餐商品资料详情
export function updateOrdermealGoods (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/updateOrdermealGoods',
    method: 'put',
    data
  })
}

// 基础资料商品信息复制到扫码点餐(未上架)
export function copyBasisGoodsToOrdermeal (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/copyBasisGoodsToOrdermeal',
    method: 'post',
    data
  })
}

// 商品批量上下架
export function batchLaunch (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/batchLaunch',
    method: 'post',
    data
  })
}

// 获取扫码点餐角标[已上线、未上线、未同步]
export function goodsBadge (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/getGoodsBadge',
    method: 'get',
    params
  })
}

// 商品分类列表
export function categoryLiStTree (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/category/listTree',
    method: 'get',
    params
  })
}

// 添加商品分类
export function saveCategory (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/category/save',
    method: 'post',
    data
  })
}

// 获取分类详情
export function getDetailCategory (categoryId) {
  return request({
    url:
      '/api/system/o2othird/ordermeal/goods/category/getDetail?categoryId=' +
      categoryId,
    method: 'get'
  })
}

// 修改商品分类
export function updateCategory (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/category/update',
    method: 'put',
    data
  })
}

// 删除商品分类
export function removeCategory (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/category/remove',
    method: 'post',
    data
  })
}

// 批量修改
export function dianCanBatchUpdateGoods (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/goods/info/batchUpdateGoods',
    method: 'put',
    data
  })
}
// 全部图片
export function synAllImageBasisGoodsToMall() {
  return request({
      url: '/api/system/o2othird/ordermeal/goods/info/synAllImageBasisGoodsToMall',
      method: 'post'
  })
}
// 详情图片
export function dianCanSynFindImageBasisGoodsToMall(data) {
  return request({
      url: '/api/system/o2othird/ordermeal/goods/info/synFindImageBasisGoodsToMall',
      method: 'post',
      data
  })
}