import request from '@/utils/request' //裱花调出
// 裱花调出列表
export function listOrder (query) {
  return request({
    url: '/api/system/decoration/bill/out/list',
    method: 'get',
    params: query
  })
}

//更新裱花调出单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/decoration/bill/out/updateBillStatus',
    method: 'post',
    data
  })
}
//修改裱花调出单据
export function updateOrder (data) {
  return request({
    url: '/api/system/decoration/bill/out/update',
    method: 'PUT',
    data
  })
}

//获取裱花调出单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/decoration/bill/out/getDetail?billId=${billId}`
  })
}

//新增裱花调出单据
export function addOrder (data) {
  return request({
    url: '/api/system/decoration/bill/out/save',
    method: 'POST',
    data
  })
}
