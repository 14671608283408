import request from '@/utils/request'

/**
 * 微信支付
 * @param params
 * @returns {AxiosPromise}
 */
// 商城支付配置详情
export function payConfigDetail(params) {
    return request({
        url: '/api/system/mall/wx/pay/config/getDetail',
        method: 'get',
        params: params
    })
}
// 新增商城支付配置
export function payConfigSave(data) {
    return request({
        url: '/api/system/mall/wx/pay/config/save',
        method: 'post',
        data
    })
}
// 编辑商城支付配置
export function payConfigUpdate(data) {
    return request({
        url: '/api/system/mall/wx/pay/config/update',
        method: 'put',
        data
    })
}

/**
 * 支付宝支付
 * @param params
 * @returns {AxiosPromise}
 */
// 商城支付配置详情
export function alipayDetail(params) {
    return request({
        url: '/api/system/mall/alipay/config/getDetail',
        method: 'get',
        params: params
    })
}
// 新增商城支付配置
export function alipaySave(data) {
    return request({
        url: '/api/system/mall/alipay/config/save',
        method: 'post',
        data
    })
}
// 编辑商城支付配置
export function alipayUpdate(data) {
    return request({
        url: '/api/system/mall/alipay/config/update',
        method: 'put',
        data
    })
}
/**
 * 扫呗支付
 * @param params
 * @returns {AxiosPromise}
 */
// 商城支付配置详情
export function saobeiDetail(params) {
    return request({
        url: '/api/system/mall/saobei/pay/config/getDetail',
        method: 'get',
        params: params
    })
}
// 新增商城支付配置
export function saobeiSave(data) {
    return request({
        url: '/api/system/mall/saobei/pay/config/save',
        method: 'post',
        data
    })
}
// 编辑商城支付配置
export function saobeiUpdate(data) {
    return request({
        url: '/api/system/mall/saobei/pay/config/update',
        method: 'put',
        data
    })
}

// ----------------------------------------------------
// 商城支付通道
export function o2oMallPayDetail(params) {
    return request({
        url: '/api/system/shop/channel/o2oMallPayDetail',
        method: 'get',
        params: params
    })
}
// 商城会员级别列表
export function o2oMallPayList(params) {
    return request({
        url: '/api/system/vip/base/level/o2oMallPayList',
        method: 'get',
        params: params
    })
}
// 获取详情
export function getDetail(params) {
    return request({
        url: '/api/system/mall/pay/config/getDetail',
        method: 'get',
        params: params
    })
}
// 获取详情
export function saveOrUpdate(data) {
    return request({
        url: '/api/system/mall/pay/config/saveOrUpdate',
        method: 'post',
        data
    })
}


// 获取详情
export function cakeGetDetail(params) {
    return request({
        url: '/api/system/o2othird/cakefreeselect/pay/config/getDetail',
        method: 'get',
        params: params
    })
}

// 获取详情
export function cakeSaveOrUpdate(data) {
    return request({
        url: '/api/system/o2othird/cakefreeselect/pay/config/saveOrUpdate',
        method: 'post',
        data
    })
}