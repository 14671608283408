import request from '@/utils/request'

//抖音产品订单分页列表
export function douYinOrderList(params) {
  return request({
    url: '/api/system/o2othird/douyin/product/order/list',
    method: 'get',
    params
  })
}

//同步抖音订单
export function synchronizationReset(data) {
  return request({
    url: '/api/system/o2othird/douyin/product/order/synchronousDouYinOrder',
    method: 'post',
    data
  })
}
