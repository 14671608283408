import request from '@/utils/request'

//会员充值消费走势分析分页列表
export function vipFillPayAnalysisList(params) {
  return request({
    url: 'api/system/shop/strategy/vipRechargeConsumeTrend/listTrend',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员充值消费走势分析列表(合计汇总)
export function vipFillPayAnalysisListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/vipRechargeConsumeTrend/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
