import request from '@/utils/request'

// 同步门店
export function syncBasicShopInfo(params) {
    return request({
        url: '/api/system/mall/shop/base/info/syncBasicShopInfo',
        method: 'post',
        params: params
    })
}
// 商城门店
export function shopInfoList(params) {
    return request({
        url: '/api/system/mall/shop/base/info/list',
        method: 'get',
        params: params
    })
}
// 门店详情
export function ShopDetail(params) {
    return request({
        url: '/api/system/mall/shop/base/info/getDetail',
        method: 'get',
        params
    })
}
// 修改门店门店信息
export function ShopUpdate(data) {
    return request({
        url: '/api/system/mall/shop/base/info/update',
        method: 'put',
        data
    })
}
// 删除门店
export function ShopRemove(data) {
    return request({
        url: '/api/system/mall/shop/base/info/remove',
        method: 'delete',
        data: data
    })
}
// 门店上下架
export function ShopBatchLaunch(data) {
    return request({
        url: '/api/system/mall/shop/base/info/batchLaunch',
        method: 'post',
        data
    })
}
// 获取门店经纬度
export function getMallShop(params) {
    return request({
        url: '/api/system/mall/shop/base/info/getMallShop',
        method: 'get',
        params
    })
}
