import request from '@/utils/request'

// 门店报损列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/room/order/list',
    method: 'get',
    params: query
  })
}

// 将订单调至指定门店
export function freshlyMoveToShop (data) {
  return request({
    url: '/api/system/freshly/baked/room/order/moveToShop',
    method: 'post',
    data
  })
}
// 将订单改为制作完成的订单
export function freshlyProduceFinish (data) {
  return request({
    url: '/api/system/freshly/baked/room/order/produceFinish',
    method: 'post',
    data
  })
}