import request from '@/utils/request'

//客供区域分类树
// export function getCodeRuleTree(query) {
//   return request({
//     url: "/api/system/partner/area/listTree",
//     method: "get",
//     params: query,
//   });
// }

// 编码规则列表
export function listCodeRule (query) {
  return request({
    url: '/api/system/sysNoRule/list',
    method: 'get',
    params: query
  })
}

// 新增编码规则
export function addcodeRule (data) {
  return request({
    url: '/api/system/sysNoRule/save',
    method: 'post',
    data: data
  })
}

//编码规则详情
export function getCodeRuleDetail (lineId) {
  return request({
    url: '/api/system/sysNoRule/getDetail',
    method: 'get',
    params: { lineId }
  })
}

// 修改编码规则
export function updatecodeRule (data) {
  return request({
    url: '/api/system/sysNoRule/update',
    method: 'put',
    data: data
  })
}

// 删除编码规则
export function delcodeRule (lineIds) {
  return request({
    url: `/api/system/sysNoRule/remove`,
    method: 'delete',
    data: lineIds
  })
}

//新增获取单据号
export function getBillNo (billType) {
  return request({
    url: `/api/system/sysNoRule/getBillNo?billType=${billType}`,
    method: 'POST'
  })
}

//购买流程获取单据编号
export function getTemplateTenantBillNo () {
  return request({
    url: `/api/system/tenant/account/order/getOrderNo`
  })
}

// 编码规则列表
export function validateTelephone (query) {
  return request({
    url: '/api/system/saas/tenant/validateTelephone',
    method: 'get',
    params: query
  })
}
