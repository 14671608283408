import request from '@/utils/request'
// 裱花原料列表
export function listOrder (query) {
  return request({
    url: '/api/system/decoration/bill/picking/list',
    method: 'get',
    params: query
  })
}

//更新裱花原料单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/decoration/bill/picking/updateBillStatus',
    method: 'post',
    data
  })
}
//修改裱花原料单据
export function updateOrder (data) {
  return request({
    url: '/api/system/decoration/bill/picking/update',
    method: 'PUT',
    data
  })
}

//获取裱花原料单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/decoration/bill/picking/getDetail?billId=${billId}`
  })
}

//新增裱花原料单据
export function addOrder (data) {
  return request({
    url: '/api/system/decoration/bill/picking/save',
    method: 'POST',
    data
  })
}
