import request from '@/utils/request'

//决策商品销售排名分页列表
export function gooodsSaleList(params) {
  return request({
    url: '/api/system/shop/strategy/gooodsSale/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//决策商品销售排名分页汇总
export function gooodsSaleListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/gooodsSale/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
