import request from '@/utils/request'

// 加盟店配送单
export function franchiseDifferenceOutList(params) {
  return request({
    url: '/api/system/franchise/store/bill/out/differenceOutList',
    method: 'get',
    params
  })
}

// 加盟店配送的差异单
export function franchiseDifferenceList(params) {
  return request({
    url: '/api/system/franchise/store/bill/in/differenceList',
    method: 'get',
    params
  })
}