import request from '@/utils/request'


//会员忠诚度分析列表
export function listVipLoyalty(params) {
  return request({
    url: '/api/system/vip/base/vipcard/vipLoyalty',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员忠诚度分析列表(合计汇总)
export function listVipLoyaltySummary(params) {
  return request({
    url: '/api/system/vip/base/vipcard/vipLoyaltySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}