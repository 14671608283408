import request from '@/utils/request'

// 管理后台用户登录方法
export function login(username, password, tenantId, loginFrom) {
  const data = {
    loginFrom: loginFrom || 1, //登录端口(1: 管理后台登录; 2: 收银端登录 3: 微信端登录 4: 裱花app端登录 5：营业通APP端登录)
    tenantId,
    username,
    password
  }
  return request({
    url: '/api/open/system/user/login/v2',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 管理后台手机号登录方法
export function captchaLogin(data) {
  return request({
    url: '/api/open/system/user/loginByCaptcha',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 管理后台用户注册方法
export function register(params) {
  return request({
    url: '/api/open/system/user/register',
    headers: {
      isToken: false
    },
    method: 'post',
    params
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/api/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/api/open/system/captcha/getImageCode',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 通过手机号码查询用户
export function findByTelephoneAPI(params) {
  return request({
    url: '/api/system/user/findByTelephone',
    method: 'get',
    params
  })
}

//通过手机号查询用户(新)
export function validateUserTelephone(params) {
  return request({
    url: '/api/system/user/validateUserTelephone',
    method: 'get',
    params
  })
}

// 重置用户密码  登录之后
export function resetPwdAPI(data) {
  return request({
    url: '/api/system/user/resetPwd',
    method: 'put',
    data: data
  })
}

// 获取系统环境配置
export function environment() {
  return request({
    url: '/api/open/system/environment/getEnvironment',
    method: 'get'
  })
}

// 校验手机号码是否存在
export function validateTelephone(telephone) {
  return request({
    url: `/api/open/system/user/validateTelephone?telephone=${telephone}`,
    method: 'get'
  })
}
// 重置用户密码  登录之前
export function recoverPassword(data) {
  return request({
    url: '/api/open/system/user/recoverPassword',
    method: 'POST',
    data
  })
}

//账号已登录 强制清除登录用户token
export function unlockLoginUser(unlockLoginKey) {
  return request({
    url: `/api/open/system/user/unlockLoginUser?unlockLoginKey=${unlockLoginKey}`,
    method: 'DELETE'
  })
}
