import request from '@/utils/request'

// 分页列表
export function bargainCaseList(params) {
    return request({
        url: `/api/system/mall/marketing/bargain/case/list`,
        method: 'GET',
        params
    })
}
// 分页列表
export function getMallMarketingBargainOrderLog(params) {
    return request({
        url: `/api/system/mall/marketing/bargain/case/getMallMarketingBargainOrderLog`,
        method: 'GET',
        params
    })
}
// 新增
export function bargainCaseSave (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/save',
        method: 'post',
        data
    })
}
// 复制
export function bargainCaseCopy (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/copyActivity',
        method: 'post',
        data
    })
}
// 详情
export function bargainCaseDetail(params) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/getDetail',
        method: 'get',
        params: params
    })
}
// 编辑
export function bargainCaseUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/update',
        method: 'put',
        data
    })
}
// 审核
export function bargainCaseAudit (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/audit',
        method: 'put',
        data
    })
}
// 启用/禁用
export function bargainCaseEnable (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/updateIsStop',
        method: 'put',
        data
    })
}
// 删除
export function bargainCaseDelete (data) {
    return request({
        url: '/api/system/mall/marketing/bargain/case/delete',
        method: 'delete',
        data
    })
}