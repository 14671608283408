import request from '@/utils/request'

// 客供资料分组树形结构
export function listTree (query) {
  return request({
    url: '/api/system/partner/group/listTree',
    method: 'get',
    params: query
  })
}

// 客供资料分组列表
export function listGroup (query) {
  return request({
    url: '/api/system/partner/group/list',
    method: 'get',
    params: query
  })
}

// 查询客供资料分组详细
export function getGroupDetail (groupId) {
  return request({
    url: '/api/system/partner/group/getDetail?groupId=' + groupId,
    method: 'get'
  })
}

// 新增客供资料分组
export function addGroup (group) {
  return request({
    url: '/api/system/partner/group/save',
    method: 'post',
    data: group
  })
}

// 修改客供资料分组
export function updateGroup (group) {
  return request({
    url: '/api/system/partner/group/update',
    method: 'put',
    data: group
  })
}

// 删除客供资料分组
export function delGroup (groupIds) {
  return request({
    url: '/api/system/partner/group/remove',
    method: 'delete',
    data: groupIds
  })
}

// 还原客供分组
export function restorePartnerGroup (groupIds) {
  return request({
    url: '/api/system/partner/group/restorePartnerGroup',
    method: 'POST',
    data: { groupIds }
  })
}
