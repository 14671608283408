import request from '@/utils/request'

//门店分页列表
export function cakefreeselectShopListAPI (params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/shop/list',
    method: 'get',
    params
  })
}

//门店批量上下架
export function cakefreeselectBatchLaunch (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/shop/batchLaunch',
    method: 'post',
    data
  })
}

//同步门店
export function cakefreeselectSyncBasicShopInfoAPI (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/shop/syncBasicShopInfo',
    method: 'post',
    data
  })
}

// //门店详情
export function cakefreeselectGetDetail (shopId) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/shop/getDetail?shopId=' + shopId,
    method: 'get'
  })
}

//修改门店
export function cakefreeselectUpdate (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/shop/update',
    method: 'put',
    data
  })
}


// //删除门店
// export function removeAPI (data) {
//   return request({
//     url: '/api/system/o2othird/ordermeal/shop/remove',
//     method: 'delete',
//     data
//   })
// }




