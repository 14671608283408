import request from '@/utils/request'

// 商城支付通道
export function o2oMallPayDetail(params) {
  return request({
    url: '/api/system/shop/channel/o2oMallPayDetail',
    method: 'get',
    params: params
  })
}

// 商城会员级别列表   未使用
export function o2oMallPayList(params) {
  return request({
    url: '/api/system/vip/base/level/o2oMallPayList',
    method: 'get',
    params: params
  })
}

// 获取扫码点餐支付配置详情
export function getDetail(params) {
  return request({
    url: '/api/system/o2othird/ordermeal/pay/config/getDetail',
    method: 'get',
    params: params
  })
}

// 新增或更新扫码点餐支付配置
export function saveOrUpdate(data) {
  return request({
    url: '/api/system/o2othird/ordermeal/pay/config/saveOrUpdate',
    method: 'post',
    data
  })
}
