import request from '@/utils/request'

//更新现烤盘点单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/freshly/baked/bill/check/updateBillStatus',
    method: 'post',
    data
  })
}

// 现烤盘点单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/bill/check/list',
    method: 'get',
    params: query
  })
}

//获取现烤盘点单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/freshly/baked/bill/check/getDetail?billId=${billId}`
  })
}

//新增现烤盘点单据
export function addOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/check/save',
    method: 'POST',
    data
  })
}

//修改现烤盘点单据
export function updateOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/check/update',
    method: 'PUT',
    data
  })
}

//查账面数库存
export function seeCheckNumber (data) {
  return request({
    url: '/api/system/shop/bill/base/findStockQty',
    method: 'POST',
    data
  })
}
