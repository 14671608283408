import request from '@/utils/request'

// 加盟分店分页列表
export function listFranchiseShopInfo(query) {
  return request({
    url: '/api/system/franchise/shop/base/info/list',
    method: 'get',
    params: query
  })
}

// 查询加盟分店详细
export function getFranchiseShopInfoDetail(shopId) {
  return request({
    url: '/api/system/franchise/shop/base/info/getDetail?shopId=' + shopId,
    method: 'get'
  })
}

// 新增加盟分店
export function addFranchiseShopInfo(data) {
  return request({
    url: '/api/system/franchise/shop/base/info/save',
    method: 'post',
    data
  })
}

// 修改加盟分店
export function updateFranchiseShopInfo(data) {
  return request({
    url: '/api/system/franchise/shop/base/info/update',
    method: 'put',
    data
  })
}

// 删除加盟分店
export function delFranchiseShopInfo(data) {
  return request({
    url: '/api/system/franchise/shop/base/info/remove',
    method: 'delete',
    data
  })
}

// 获取加盟店机构树
export function getDeptTreeFranchise(params) {
  return request({
    url: '/api/system/franchise/shop/base/info/getDeptTree',
    method: 'get',
    params
  })
}
// 还原加盟分店
export function restoreFranchiseShopInfo(data) {
  return request({
    url: '/api/system/franchise/shop/base/info/restoreShopInfo',
    method: 'post',
    data
  })
}
