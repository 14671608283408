import request from '@/utils/request'

//销售环比分页列表
export function saleQoQList(params) {
  return request({
    url: '/api/system/shop/strategy/saleRingAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售环比分页汇总
export function saleQoQListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/saleRingAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
