import request from '@/utils/request'

// 商城订单列表
export function orderList(params) {
    return request({
        url: '/api/system/mall/online/order/list',
        method: 'get',
        params: params
    })
}
// 订单详情
export function getOrderDetail(params) {
    return request({
        url: '/api/system/mall/online/order/getDetail',
        method: 'get',
        params: params
    })
}
// 修改订单状态
export function updateOrderStatus(data) {
    return request({
        url: '/api/system/mall/online/order/updateOrderStatus',
        method: 'post',
        data
    })
}
// 获取门店支付方式
export function shopBasPayList(params) {
    return request({
        url: '/api/system/shop/base/pay/list',
        method: 'get',
        params: params
    })
}

// 商城支付订单
export function reservePayDetailList(params) {
    return request({
        url: '/api/system/mall/order/reserve/pay/detail/list',
        method: 'get',
        params: params
    })
}
// 申请退款
export function orderRefundOrder(data) {
    return request({
        url: '/api/system/mall/online/order/refundOrder',
        method: 'post',
        data
    })
}




//门店预定单分页列表   分页列表
export function orderListAPI(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   分页列表
export function o2oOrderListAPI(params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/order/getShopPredetermineOrderList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   合计行
export function orderListSummaryAPI(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
//门店预定单分页列表   合计行
export function o2oOrderListSummaryAPI(params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/order/getShopPredetermineOrderListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按日期）   分页列表
export function getShopPredetermineDateList(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineDateList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按日期）   合计行
export function getShopPredetermineDateListSummary(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineDateListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按门店）   分页列表
export function getShopPredetermineShopList(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineShopList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按门店）   合计行
export function getShopPredetermineShopListSummary(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineShopListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//预订查询汇总（按商品）   分页列表
export function getShopPredetermineGoodsList(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineGoodsList',
    method: 'get',
    timeout: 30000,
    params
  })
}
//预订查询汇总（按商品）   合计行
export function getShopPredetermineGoodsListSummary(params) {
  return request({
    url: '/api/system/mobile/base/predetermineOrder/getShopPredetermineGoodsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}



// 门店收款明细分析
/* export function payDetailAPI(query) {
  return request({
    url: "/api/system/shop/report/pay/analysis/listShopPayDetail",
    method: "get",
    params: query,
  });
} */
//改版
// 门店收款明细分析
export function paymentDetailsList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentDetailsList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 门店收款明细分析  合计行
export function paymentDetailsListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentDetailsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(门店+收款方式+收银员)
export function shopPaymentShopCashierList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentShopCashierList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(门店+收款方式+收银员)  合计行
export function shopPaymentShopCashierListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentShopCashierListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(门店)
export function shopPaymentList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(门店)  合计行
export function shopPaymentListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(收款方式)
export function paymentList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(收款方式)  合计行
export function paymentListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(收款方式+收银员)
export function shopPaymentCashierList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentCashierList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(收款方式+收银员)  合计行
export function shopPaymentCashierListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentCashierListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询(日期)
export function shopPaymentDateList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentDateList',
    method: 'get',
    timeout: 30000,
    params
  })
}
// 收款汇总查询(日期)  合计行
export function shopPaymentDateListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/shopPaymentDateListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款汇总查询()  查询充值订单详情
export function paymentDetailsFill(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentDetailsFill',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款商品明细分析
export function paymentGoodsDetailsList(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentGoodsDetailsList',
    method: 'get',
    timeout: 30000,
    params
  })
}

// 收款商品明细分析  合计行
export function paymentGoodsDetailsListSummary(params) {
  return request({
    url: '/api/system/mall/report/pay/analysis/paymentGoodsDetailsListSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}



