import request from '@/utils/request'
//现烤生产报表      分页列表
export function shopProduceListAPI(params) {
  return request({
    url: '/api/system/freshly/baked/bill/produce/report/listBillProduce',
    method: 'get',
    timeout: 30000,
    params
  })
}
//现烤生产报表      合计行
export function shopProduceListSummaryAPI(params) {
  return request({
    url: '/api/system/freshly/baked/bill/produce/report/listBillProduceSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}