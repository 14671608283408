import request from '@/utils/request'

// 加盟店配送单
export function franchiseHttpOutList(params) {
  return request({
    url: '/api/system/franchise/store/bill/out/list',
    method: 'get',
    params
  })
}
