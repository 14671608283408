import request from '@/utils/request'

//销售时间段商品分析分页列表
export function saleTimeGoodsList(params) {
  return request({
    url: '/api/system/shop/strategy/saleTimeSlotGoods/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}
