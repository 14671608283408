import request from '@/utils/request'

//门店商品月分析分页列表
export function shopGoodsMonthlyTotalList(params) {
  return request({
    url: '/api/system/shop/strategy/shopGoodsMonthAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//门店商品月分析分页汇总
export function shopGoodsMonthlyTotalListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/shopGoodsMonthAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
