import request from '@/utils/request'

//会员信息分页
export function franchiseVipcardList(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员信息余额汇总
export function franchiseVipcardListSummary(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//加盟店会员余额汇总查询明细
export function listBalanceFillDetail(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listBalanceFillDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//加盟店会员余额汇总查询明细统计
export function listBalanceFillDetailSummary(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listBalanceFillDetailSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//加盟店会员充值查询明细
export function listFillDetail(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listFillDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员充值查询明细
export function listFillDetailSummary(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listFillDetailSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员支付查询明细
export function listPayDetail(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listPayDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员支付查询汇总
export function listPayDetailSummary(params) {
  return request({
    url: '/api/system/franchise/vip/base/vipcard/listPayDetailSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}