import request from '@/utils/request'

// 运费模板列表
export function templateList(params) {
    return request({
        url: 'api/system/mall/shipping/template/list',
        method: 'get',
        params: params
    })
}
// 新增运费模板
export function templateSave(data) {
    return request({
        url: '/api/system/mall/shipping/template/save',
        method: 'post',
        data
    })
}

// 编辑运费模板
export function templateUpdate(data) {
    return request({
        url: '/api/system/mall/shipping/template/update',
        method: 'put',
        data
    })
}

// 删除运费模板
export function templateRemove(templateIds) {
    return request({
        url: '/api/system/mall/shipping/template/deleteByTemplate',
        method: 'delete',
        data: templateIds
    })
}
// 运费模板详情
export function templateGetDetail(params) {
    return request({
        url: '/api/system/mall/shipping/template/getDetail',
        method: 'get',
        params
    })
}

// 运费模板商品分页列表
export function findTemplateGoods(params) {
    return request({
        url: '/api/system/mall/shipping/template/findTemplateGoods',
        method: 'get',
        params: params
    })
}

