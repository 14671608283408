import request from '@/utils/request'

//商品畅销分页列表
export function franchiseShopCompositeList(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopComprehensiveAnalysis/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//商品畅销分页 汇总
export function franchiseShopCompositeListSummary(params) {
  return request({
    url: '/api/system/franchise/shop/strategy/shopComprehensiveAnalysis/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
