import request from '@/utils/request'

//笔单分析
export function penUnitPriceAnalysis(data) {
  return request({
    url: '/api/system/shop/strategy/penUnitPrice/ranking/penUnitPriceAnalysis',
    method: 'post',
    timeout: 30000,
    data
  })
}
