import request from '@/utils/request'

// 分页列表
export function couponFissionList(params) {
    return request({
        url: `/api/system/mall/marketing/fission/case/list`,
        method: 'GET',
        params
    })
}
// 分页列表明细
export function getMarketingCouponFissionCaseLog(params) {
    return request({
        url: `/api/system/mall/marketing/fission/case/getMarketingCouponFissionCaseLog`,
        method: 'GET',
        params
    })
}
// 新增
export function couponFissionSave (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/save',
        method: 'post',
        data
    })
}
// 复制
export function couponFissionCopy (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/copyActivity',
        method: 'post',
        data
    })
}
// 详情
export function couponFissionDetail(params) {
    return request({
        url: '/api/system/mall/marketing/fission/case/getDetail',
        method: 'get',
        params: params
    })
}

// 编辑
export function couponFissionUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/update',
        method: 'put',
        data
    })
}
// 审核
export function couponFissionAudit (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/audit',
        method: 'put',
        data
    })
}
// 启用/禁用
export function couponFissionEnable (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/updateIsStop',
        method: 'put',
        data
    })
}
// 删除
export function couponFissionDelete (data) {
    return request({
        url: '/api/system/mall/marketing/fission/case/deleteByIds',
        method: 'delete',
        data
    })
}