import request from '@/utils/request'

// 产品列表
export const listProduct = params =>
  request({
    url: '/api/system/tenant/product/purchase/listProduct',
    method: 'get',
    params
  })

// 生成订单付款码
export const orderPay = data =>
  request({
    url: '/api/system/tenant/product/purchase/orderPay',
    method: 'post',
    data
  })

// 查看详情
export const orderDetail = data => {
  return request({
    url: '/api/system/tenant/account/order/getDetail',
    method: 'get',
    params: data
  })
}

// 创建商户
export const createTenant = data =>
  request({
    url: '/api/system/saas/tenant/createTenant',
    method: 'post',
    data
  })

// 查询
export const getOrderState = params =>
  request({
    url: '/api/system/tenant/product/purchase/getOrderState',
    method: 'get',
    params
  })

//续费状态查询
export const getOrderStateRenew = params =>
  request({
    url: '/api/system/tenant/account/order/getOrderState',
    method: 'get',
    params
  })

//检查订单支付状态
export const checkOrderPayState = params =>
  request({
    url: '/api/system/tenant/account/order/checkOrderPayState',
    method: 'get',
    params
  })
