import request from '@/utils/request'

//更新裱花盘亏单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/loss/updateBillStatus',
    method: 'post',
    data
  })
}

// 裱花盘亏单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/decoration/bill/inventory/loss/list',
    method: 'get',
    params: query
  })
}

//获取裱花盘亏单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/decoration/bill/inventory/loss/getDetail?billId=${billId}`
  })
}

//新增裱花盘亏单据
export function addOrder (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/loss/save',
    method: 'POST',
    data
  })
}

//修改裱花盘亏单据
export function updateOrder (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/loss/update',
    method: 'PUT',
    data
  })
}
