import request from '@/utils/request'

//加盟门店库存查询   分页列表
export function franchiseStoreQueryAPI(params) {
  return request({
    url: '/api/system/franchise/shop/report/inventory/query/listShopInventoryQuery',
    method: 'get',
    timeout: 30000,
    params
  })
}

//加盟门店库存明细查询
export function franchiseGetStoreBookDetail(params) {
  return request({
    url: '/api/system/franchise/shop/report/inventory/query/getStoreBookDetail',
    method: 'get',
    timeout: 30000,
    params
  })
}

//加盟门店库存  合计行
export function franchiseListShopInventoryQuerySummary(params) {
  return request({
    url: '/api/system/franchise/shop/report/inventory/query/listShopInventoryQuerySummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
