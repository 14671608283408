import request from '@/utils/request'

//蛋糕自选产品订单分页列表
export function getTradeProductOrder(params) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/listOrderPage',
    method: 'GET',
    params
  })
}

//获取要续费的蛋糕自选产品
export function getTradeProductToRenew(data) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/getProductToRenew',
    method: 'post',
    data
  })
}

//获取明细
export function getOrderDetail(params) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/getOrderDetail',
    method: 'get',
    params
  })
}

// 生成订单(需要先生成订单后再去调用支付二维码)1
export function generateOrder(data) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/generateOrder',
    method: 'post',
    data
  })
}

// 生成付款码
export function orderPay(data) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/orderPay',
    method: 'post',
    data
  })
}

// 扫码点餐产品续费
export function tradeProductRenew(data) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/productRenew',
    method: 'post',
    data
  })
}

//获取订单支付状态
export function getOrderState(params) {
  return request({
    url: '/api/system/saas/cakefreeselect/product/getOrderState',
    method: 'get',
    params
  })
}
