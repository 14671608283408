import request from '@/utils/request'
//裱花生产报表      分页列表
export function shopProduceListAPI(params) {
  return request({
    url: '/api/system/decoration/bill/produce/report/listBillProduce',
    method: 'get',
    timeout: 30000,
    params
  })
}
//裱花生产报表      合计行
export function shopProduceListSummaryAPI(params) {
  return request({
    url: '/api/system/decoration/bill/produce/report/listBillProduceSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}