import request from '@/utils/request'

//客供区域分类树
export function getAreaTree (query) {
  return request({
    url: '/api/system/partner/area/listTree',
    method: 'get',
    params: query
  })
}

// 客户资料列表
export function listCustomer (query) {
  return request({
    url: '/api/system/partner/customer/list',
    method: 'get',
    params: query
  })
}

//获取客户资料详情
export function customerDetail (partnerId) {
  return request({
    url: '/api/system/partner/customer/getDetail',
    method: 'get',
    params: { partnerId }
  })
}

// 添加客户资料
export function addCustomer (data) {
  return request({
    url: '/api/system/partner/customer/save',
    method: 'post',
    data: data
  })
}

// 修改客户资料
export function updateCustomer (data) {
  return request({
    url: '/api/system/partner/customer/update',
    method: 'put',
    data: data
  })
}

//批量修改客户属性
export function statusCustomer (data) {
  return request({
    url: '/api/system/partner/customer/batchUpdateCustomerFields',
    method: 'POST',
    data: data
  })
}

// 删除客户资料
export function delCustomer (partnerIds) {
  return request({
    url: `/api/system/partner/customer/remove`,
    method: 'delete',
    data: partnerIds
  })
}
// 导入
export function uploadFileRequest (data) {
  return request({
    url: '/api/system/partner/customer/importPartnerInfo',
    method: 'POST',
    data
  })
}

// 还原客供分组
export function restorePartnerCustomerInfo (partnerIds) {
  return request({
    url: '/api/system/partner/customer/restorePartnerCustomerInfo',
    method: 'POST',
    data: { partnerIds }
  })
}
