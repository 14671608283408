import request from '@/utils/request'

//获取抖音参数配置
export function getSysDouyinParamConfig () {
  return request({
    url: '/api/system/sysO2OConfigParam/getSysDouyinParamConfig',
    method: 'get'
  })
}
// 添加修改
export function saveSysDouyinParamConfig (data) {
  return request({
    url: '/api/system/sysO2OConfigParam/saveSysDouyinParamConfig',
    method: 'put',
    data
  })
}
