import request from '@/utils/request'

//更新裱花盘盈单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/surplus/updateBillStatus',
    method: 'post',
    data
  })
}

// 裱花盘盈单据分页列表
export function listOrder (query) {
  return request({
    url: '/api/system/decoration/bill/inventory/surplus/list',
    method: 'get',
    params: query
  })
}

//获取裱花盘盈单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/decoration/bill/inventory/surplus/getDetail?billId=${billId}`
  })
}

//新增裱花盘盈单据
export function addOrder (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/surplus/save',
    method: 'POST',
    data
  })
}

//修改裱花盘盈单据
export function updateOrder (data) {
  return request({
    url: '/api/system/decoration/bill/inventory/surplus/update',
    method: 'PUT',
    data
  })
}
