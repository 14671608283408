import request from '@/utils/request'

// 商城资料分页列表
export function fightGroupList(params) {
    return request({
        url: `/api/system/mall/marketing/fight/group/list`,
        method: 'GET',
        params
    })
}
// 审核
export function fightGroupAudit (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/audit',
        method: 'put',
        data
    })
}
// 复制
export function fightGroupCopyActivity (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/copyActivity',
        method: 'post',
        data
    })
}
// 启用/禁用
export function fightGroupUpdateIsEnable (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/updateIsEnable',
        method: 'put',
        data
    })
}
// 删除
export function fightGroupUpdateDelete (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/delete',
        method: 'delete',
        data
    })
}
// 新增
export function fightGroupUpdateGroupSave (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/save',
        method: 'post',
        data
    })
}
// 详情
export function fightGroupGetDetail(params) {
    return request({
        url: '/api/system/mall/marketing/fight/group/getDetail',
        method: 'get',
        params: params
    })
}
// 修改
export function fightGroupUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/fight/group/update',
        method: 'put',
        data
    })
}
// 拼团活动商品校验
export function groupVerificationActivityGoods (params) {
    return request({
        url: '/api/system/mall/marketing/fight/group/verificationActivityGoods',
        method: 'get',
        params: params
    })
}
