import request from '@/utils/request'

// 素材类型分页列表树
export function materialTypeListTreeAPI (params) {
  return request({
    url: '/api/system/mall/material/type/listTree',
    method: 'get',
    params
  })
}
// 新增素材类型
export function materialTypeAddAPI (data) {
  return request({
    url: '/api/system/mall/material/type/save',
    method: 'post',
    data
  })
}
//获取素材类型详情
export function materialTypeDetailAPI (materialTypeId) {
  return request({
    url:
      '/api/system/mall/material/type/getDetail?materialTypeId=' +
      materialTypeId,
    method: 'get'
  })
}
// 修改素材类型
export function materialTypeUpdateAPI (data) {
  return request({
    url: '/api/system/mall/material/type/update',
    method: 'put',
    data
  })
}
//删除素材类型
export function materialTypeRemoveAPI (materialTypeIds) {
  return request({
    url: '/api/system/mall/material/type/delete',
    method: 'delete',
    data: materialTypeIds
  })
}

// 给商户同步默认素材类型库类型
export function materialTypeSynchronizeAPI (params) {
  return request({
    url: '/api/system/mall/material/type/syncDefaultMallMaterialType',
    method: 'post',
      params
  })
}
