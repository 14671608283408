import request from '@/utils/request'

// 领券中心方案分页列表
export function drawCouponList(params) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/list`,
        method:'get',
        params
    })
}

// 新增领券中心方案
export function addDrawCoupon(data) {
    return request({
        url: '/api/system/marketing/base/coupon/case/draw/save',
        method: 'post',
        data
    })
}

// 修改领券中心方案
export function updateDrawCoupon(data) {
    return request({
        url: '/api/system/marketing/base/coupon/case/draw/update',
        method: 'put',
        data
    })
}

// 领券中心方案详情
export function getDetailDrawCoupon(billId) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/getDetail?billId=${billId}`,
    })
}

// 删除领券中心方案
export function delDrawCoupon(billIds) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/remove`,
        method:'delete',
        data:billIds
    })
}

// 是否停用领券中心方案
export function isStopDrawCoupon(billIds) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/isStop`,
        method:'post',
        data:billIds
    })
}

// 复制领券中心方案
export function copyDrawCoupon(billIds) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/copy`,
        method:'post',
        data:billIds
    })
}

// 审核/反审核领券中心方案
export function carefulDrawCoupon(billIds) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/careful`,
        method:'post',
        data:billIds
    })
}

// 审核/反审核领券中心方案(单个有返回数据)
export function oneCarefulDrawCoupon(billId) {
    return request({
        url: `/api/system/marketing/base/coupon/case/draw/oneCareful/${billId}`,
        method:'post',
    })
}