import request from '@/utils/request'

// 新增页面组件模板配置
export function templateSave(data) {
    return request({
        url: '/api/system/mall/page/config/template/save',
        method: 'post',
        data
    })
}
// 模板配置分页列表
export function templateList(params) {
    return request({
        url: '/api/system/mall/page/config/template/list',
        method: 'get',
        params: params
    })
}
// 批量更新模板配置状态(上线|下线)
export function batchUpdateTemplateStatus(data) {
    return request({
        url: '/api/system/mall/page/config/template/batchUpdateTemplateStatus',
        method: 'put',
        data
    })
}
// 自定义组件拼团商品分页列表
export function activityGoodList(params) {
    return request({
        url: '/api/system/mall/marketing/page/plugin/list',
        method: 'get',
        params: params
    })
}
// 获取模板
export function configTemplateList(params) {
    return request({
        url: '/api/system/mall/page/config/template/list',
        method: 'get',
        params: params
    })
}
// 删除模板
export function deleteTemplate(data) {
    return request({
        url: '/api/system/mall/page/config/template/deleteTemplate',
        method: 'delete',
        data: data
    })
}
// 修改模板
export function updateTemplate(data) {
    return request({
        url: '/api/system/mall/page/config/template/update',
        method: 'put',
        data
    })
}

export function restoreTemplate(data) {
    return request({
        url: '/api/system/mall/page/config/template/restorePrintTemplate',
        method: 'POST',
        data
    })
}

