import request from '@/utils/request'

// 分页列表
export function carveUpCouponList(params) {
    return request({
        url: `/api/system/mall/marketing/carve/up/list`,
        method: 'GET',
        params
    })
}
// 瓜分分页列表 明细
export function getMallMarketingCouponCarveUpLog(params) {
    return request({
        url: `/api/system/mall/marketing/carve/up/getMallMarketingCouponCarveUpLog`,
        method: 'GET',
        params
    })
}
// 新增
export function carveUpCouponSave (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/save',
        method: 'post',
        data
    })
}
// 复制
export function carveUpCouponCopy (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/copyActivity',
        method: 'post',
        data
    })
}
// 详情
export function carveUpCouponDetail(params) {
    return request({
        url: '/api/system/mall/marketing/carve/up/getDetail',
        method: 'get',
        params: params
    })
}

// 编辑
export function carveUpCouponUpdate (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/update',
        method: 'put',
        data
    })
}
// 审核
export function carveUpCouponAudit (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/audit',
        method: 'put',
        data
    })
}
// 启用/禁用
export function carveUpCouponEnable (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/updateIsStop',
        method: 'put',
        data
    })
}
// 删除
export function carveUpCouponDelete (data) {
    return request({
        url: '/api/system/mall/marketing/carve/up/deleteByIds',
        method: 'delete',
        data
    })
}