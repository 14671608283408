import request from '@/utils/request'

//会员每日每月情况分析分页列表
export function vipDayAnalysisList(params) {
  return request({
    url: '/api/system/shop/strategy/vipSituation/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员每日每月情况分析列表(合计汇总)
export function vipDayAnalysisListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/vipSituation/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
