import request from '@/utils/request'

//会员增长分析分页列表
export function vipGrowthAnalysisList(params) {
  return request({
    url: '/api/system/shop/strategy/vipGrowthAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//会员增长分析列表(合计汇总)
export function vipGrowthAnalysisListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/vipGrowthAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
