import request from '@/utils/request'

// 获取商户页面组件模板配置
export function getDetail(params) {
  return request({
    url: '/api/system/o2othird/ordermeal/page/template/config/getDetail',
    method: 'get',
    params
  })
}

// 新增或修改页面组件模板配置
export function saveOrUpdatePageTemplate(data) {
  return request({
    url: '/api/system/o2othird/ordermeal/page/template/config/saveOrUpdatePageTemplate',
    method: 'post',
    data
  })
}