import request from '@/utils/request'

//会员近期充值消费分析分页列表
export function vipLastFillPayAnalysisList(params) {
  return request({
    url: '/api/system/shop/strategy/recentConsumerAnalysis/vipRecentConsumerAnalysis',
    method: 'post',
    timeout: 30000,
    params
  })
}
