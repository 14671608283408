import request from '@/utils/request'

// 门店商品分页列表
export function franchiseGoodsList(params) {
  return request({
    url: '/api/system/franchise/base/goods/list',
    method: 'get',
    params
  })
}

// 新增更新门店商品
export function saveOrUpdate(data) {
  return request({
    url: '/api/system/franchise/base/goods/saveOrUpdate',
    method: 'post',
    data
  })
}
