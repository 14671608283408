import request from '@/utils/request'

export function listBankAccount (data) {
  return request({
    url: '/api/system/finance/BankAccount/listBankAccount',
    method: 'POST',
    data: data
  })
}
export function delBankAccount (data) {
  return request({
    url: '/api/system/finance/BankAccount/delBankAccount',
    method: 'POST',
    data: data
  })
}
export function addBankAccount (data) {
  return request({
    url: '/api/system/finance/BankAccount/addBankAccount',
    method: 'POST',
    data: data
  })
}
export function modifyStopStatus (data) {
  return request({
    url: '/api/system/finance/BankAccount/modifyStopStatus',
    method: 'POST',
    data: data
  })
}
export function modifyBankAccount (data) {
  return request({
    url: '/api/system/finance/BankAccount/modifyBankAccount',
    method: 'POST',
    data: data
  })
}

