import request from '@/utils/request'

//销售同比分页列表
export function saleYoYList(params) {
  return request({
    url: '/api/system/shop/strategy/saleYOYAnalysis/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售同比分页汇总
export function saleYoYListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/saleYOYAnalysis/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
