import request from '@/utils/request'
// 现烤原料列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/bill/picking/list',
    method: 'get',
    params: query
  })
}

//更新现烤原料单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/freshly/baked/bill/picking/updateBillStatus',
    method: 'post',
    data
  })
}
//修改现烤原料单据
export function updateOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/picking/update',
    method: 'PUT',
    data
  })
}

//获取现烤原料单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/freshly/baked/bill/picking/getDetail?billId=${billId}`
  })
}

//新增现烤原料单据
export function addOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/picking/save',
    method: 'POST',
    data
  })
}
