import request from '@/utils/request'

// 商城资料分页列表
export function goodsList(params) {
    return request({
        url: '/api/system/mall/goods/info/list',
        method: 'get',
        params: params
    })
}

// 添加商品到商城商品未上架
export function copyBasisGoodsToMall(data) {
    return request({
        url: '/api/system/mall/goods/info/copyBasisGoodsToMall',
        method: 'post',
        data
    })
}
// 商品批量上架
export function batchLaunch(data) {
    return request({
        url: '/api/system/mall/goods/info/batchLaunch',
        method: 'post',
        data
    })
}

// 批量修改
export function batchUpdateGoods(data) {
    return request({
        url: '/api/system/mall/goods/info/batchUpdateGoods',
        method: 'put',
        data
    })
}
// 获取商品详情
export function goodsGetDetail(params) {
    return request({
        url: '/api/system/mall/goods/info/getMallGoodsDetail',
        method: 'get',
        params: params
    })
}
// 商品资料修改
export function updateMallGoods(data) {
    return request({
        url: '/api/system/mall/goods/info/updateMallGoods',
        method: 'put',
        data
    })
}
// 上传视频
export function uploadVideo(data) {
    return request({
        url: '/api/open/oss/video/upload',
        method: 'post',
        data
    })
}
// 删除视频
export function deleteVideoById(data) {
    return request({
        url: '/api/system/mall/goods/info/deleteVideo?videoId='+ data.videoId,
        method: 'delete'
    })
}
// 获取线上商城角标
export function goodsBadge(params) {
    return request({
        url: '/api/system/mall/goods/info/getGoodsBadge',
        method: 'get',
        params: params
    })
}
// 商品分类树
export function categoryLiStTree(params) {
    return request({
        url: '/api/system/mall/goods/category/listTree',
        method: 'get',
        params: params
    })
}
// 图片
export function synAllImageBasisGoodsToMall() {
    return request({
        url: '/api/system/mall/goods/info/synAllImageBasisGoodsToMall',
        method: 'post'
    })
}
// 加值
export function synAllUnitMachiningBasisGoodsToMall() {
    return request({
        url: '/api/system/mall/goods/info/synAllUnitMachiningBasisGoodsToMall',
        method: 'post'
    })
}

// 图片
export function synFindImageBasisGoodsToMall(data) {
    return request({
        url: '/api/system/mall/goods/info/synFindImageBasisGoodsToMall',
        method: 'post',
        data
    })
}

// 加值
export function synFindUnitMachiningBasisGoodsToMall(data) {
    return request({
        url: '/api/system/mall/goods/info/synFindUnitMachiningBasisGoodsToMall',
        method: 'post',
        data
    })
}
