import request from '@/utils/request'

//销售毛利汇总分页列表
export function saleGrossProfitTotalList(params) {
  return request({
    url: '/api/system/shop/strategy/saleGain/ranking/list',
    method: 'get',
    timeout: 30000,
    params
  })
}

//销售毛利汇总 汇总
export function saleGrossProfitTotalListSummary(params) {
  return request({
    url: '/api/system/shop/strategy/saleGain/ranking/listSummary',
    method: 'get',
    timeout: 30000,
    params
  })
}
