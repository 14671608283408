import request from '@/utils/request'

// 供应商资料列表
export function listSupplier (query) {
  return request({
    url: '/api/system/partner/supplier/list',
    method: 'get',
    params: query
  })
}

//获取供应商资料详情
export function getSupplierDetail (partnerId) {
  return request({
    url: '/api/system/partner/supplier/getDetail',
    method: 'get',
    params: { partnerId }
  })
}

// 添加供应商资料
export function addSupplier (data) {
  return request({
    url: '/api/system/partner/supplier/save',
    method: 'post',
    data: data
  })
}

// 修改供应商资料
export function updateSupplier (data) {
  return request({
    url: '/api/system/partner/supplier/update',
    method: 'put',
    data: data
  })
}

// 删除供应商资料
export function delSupplier (partnerIds) {
  return request({
    url: '/api/system/partner/supplier/remove',
    method: 'delete',
    data: partnerIds
  })
}
// 导入
export function uploadFileRequest (data) {
  return request({
    url: 'api/system/partner/supplier/importSupplierInfo',
    method: 'POST',
    data
  })
}

// 还原供应商
export function restorePartnerSupplierInfo (data) {
  return request({
    url: '/api/system/partner/supplier/restorePartnerSupplierInfo',
    method: 'POST',
    data
  })
}
