import request from '@/utils/request'

// 门店自提添加
export function deliverySroteSave(data) {
  return request({
    url: '/api/system/mall/delivery/get/in/store/save',
    method: 'post',
    data
  })
}
// 门店自提修改
export function deliverySroteUpdate(data) {
  return request({
    url: '/api/system/mall/delivery/get/in/store/update',
    method: 'put',
    data
  })
}
// 门店自提详情
export function pickUpInAstoreDetail(data) {
  return request({
    url: '/api/system/mall/delivery/get/in/store/getDetail',
    method: 'get',
    data
  })
}
// 添加-同城配送
export function TheSameCitySave(data) {
  return request({
    url: '/api/system/mall/delivery/intra/city/save',
    method: 'post',
    data
  })
}
// 修改-同城配送
export function TheSameCityUpdate(data) {
  return request({
    url: '/api/system/mall/delivery/intra/city/update',
    method: 'put',
    data
  })
}
// 同城配送详情
export function TheSameCityDetail(data) {
  return request({
    url: '/api/system/mall/delivery/intra/city/getDetail',
    method: 'get',
    data
  })
}
// 添加-快递配送
export function expressageDistriSave(data) {
  return request({
    url: '/api/system/mall/delivery/express/save',
    method: 'post',
    data
  })
}
// 自改-快递配送
export function expressageDistriUpdate(data) {
  return request({
    url: '/api/system/mall/delivery/express/update',
    method: 'put',
    data
  })
}
// 快递配送详情
export function expressageDistriDetail(data) {
  return request({
    url: '/api/system/mall/delivery/express/getDetail',
    method: 'get',
    data
  })
}

// 查询城市码
export function cityCodeList(params) {
  return request({
    url: '/api/logistics/dada/cityCodeList',
    method: 'get',
    params
  })
}

// 注册达达商户账号
export function dadaMerchantAdd(data) {
  return request({
    url: '/api/logistics/dada/merchantAdd',
    method: 'post',
    data
  })
}

// 查询达达商户账号
export function dadaGetMerchant(data) {
  return request({
    url: '/api/logistics/dada/getMerchant',
    method: 'post',
    data
  })
}

// 获取门店绑定信息详情
export function dadaGetShopBindingList(data) {
  return request({
    url: '/api/logistics/dada/getShopBindingList',
    method: 'post',
    data
  })
}

// 创建门店
export function dadaShopAdd(data) {
  return request({
    url: '/api/logistics/dada/shopAdd',
    method: 'post',
    data
  })
}

// 平台商户物流App授权
export function dataLogisticsAdd(data) {
  return request({
    url: '/api/logistics/dada/dataLogisticsAdd',
    method: 'post',
    data
  })
}

// 查询UU商户账号
export function uuGetMerchan(data) {
  return request({
    url: '/api/logistics/uu/getMerchant',
    method: 'post',
    data
  })
}

// uu商户注册
export function uuMerchantAdd(data) {
  return request({
    url: '/api/logistics/uu/merchantAdd',
    method: 'post',
    data
  })
}

// uu获取门店绑定信息详情
export function uuGetShopBindingList(data) {
  return request({
    url: '/api/logistics/uu/getShopBindingList',
    method: 'post',
    data
  })
}

// uu获取门店列表
export function uuGetShopList(data) {
  return request({
    url: '/api/logistics/uu/getShopList',
    method: 'post',
    data
  })
}

// uu新增绑定门店关系
export function uuShopBinding(data) {
  return request({
    url: '/api/logistics/uu/shopBinding',
    method: 'post',
    data
  })
}

// 查询顺丰同城配送账号
export function sfGetMerchant(data) {
  return request({
    url: '/api/logistics/sf/getMerchant',
    method: 'post',
    data
  })
}

// 获取顺丰门店绑定信息详情
export function sfGetShopBindingList(data) {
  return request({
    url: '/api/logistics/sf/getShopBindingList',
    method: 'post',
    data
  })
}

// 注册顺丰商户账号
export function sfMerchantAdd(data) {
  return request({
    url: '/api/logistics/sf/merchantAdd',
    method: 'post',
    data
  })
}

// 顺丰创建门店
export function sfShopAdd(data) {
  return request({
    url: '/api/logistics/sf/shopAdd',
    method: 'post',
    data
  })
}

// wxKD获取支持的快递公司列表
export function wxkdGetAllDelivery(data) {
  return request({
    url: '/api/logistics/weChatExpress/business/getAllDelivery',
    method: 'post',
    data
  })
}

// wxKD  绑定/解绑物流账号
export function wxkdAccount(data) {
  return request({
    url: '/api/logistics/weChatExpress/business/account',
    method: 'post',
    data
  })
}

// wxKD  获取所有绑定的物流账号
export function wxkdGetAllAccount(data) {
  return request({
    url: '/api/logistics/weChatExpress/business/getAllAccount',
    method: 'post',
    data
  })
}

// wxTC 获取门店绑定信息详情
export function wxtcGetShopBindingList(data) {
  return request({
    url: '/api/logistics/weChatSameCityDelivery/getShopBindingList',
    method: 'post',
    data
  })
}

// wxTC 创建门店
export function wxtcShopAdd(data) {
  return request({
    url: '/api/logistics/weChatSameCityDelivery/shopAdd',
    method: 'post',
    data
  })
}

// ss 授权URL
export function ssAuthorizationUrl() {
  return request({
    url: '/api/logistics/flashDelivery/authorizationUrl',
    method: 'get',
  })
}

// ss 查询授权
export function ssGetMerchant() {
  return request({
    url: '/api/logistics/flashDelivery/getMerchant',
    method: 'post',
  })
}

// ss 创建门店
export function ssShopAdd(data) {
  return request({
    url: '/api/logistics/flashDelivery/shopAdd',
    method: 'post',
    data
  })
}

// ss 门店绑定信息
export function ssGetShopBindingList(data) {
  return request({
    url: '/api/logistics/flashDelivery/getShopBindingList',
    method: 'post',
    data
  })
}

// fn 注册蜂鸟商户账号
export function fnMerchantAdd(data) {
  return request({
    url: '/api/logistics/hummingbird/merchantAdd',
    method: 'post',
    data
  })
}

// fn 蜂鸟商户
export function fnGetMerchant(data) {
  return request({
    url: '/api/logistics/hummingbird/getMerchant',
    method: 'post',
    data
  })
}

// fn 创建门店
export function fnShopAdd(data) {
  return request({
    url: '/api/logistics/hummingbird/shopAdd',
    method: 'post',
    data
  })
}

// fn 门店绑定信息
export function fnGetShopBindingList(data) {
  return request({
    url: '/api/logistics/hummingbird/getShopBindingList',
    method: 'post',
    data
  })
}

// 总体  注册商户账号
export function merchantAdds(data) {
  return request({
    url: '/api/logistics/merchantAdds',
    method: 'post',
    data
  })
}

// 总体  创建门店
export function shopAdds(data) {
  return request({
    url: '/api/logistics/shopAdds',
    method: 'post',
    data
  })
}

// 达达授权
export function dataTicket() {
  return request({
    url: '/api/logistics/dada/dataTicket',
    method: 'get',
  })
}