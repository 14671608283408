import request from '@/utils/request'

//门店分页列表
export function shopListAPI (params) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/list',
    method: 'get',
    params
  })
}

//门店批量上下架
export function batchLaunchAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/batchLaunch',
    method: 'post',
    data
  })
}

//删除门店
export function removeAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/remove',
    method: 'delete',
    data
  })
}

//门店详情
export function getDetailAPI (shopId) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/getDetail?shopId=' + shopId,
    method: 'get'
  })
}

//修改门店
export function updateAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/update',
    method: 'put',
    data
  })
}

//同步门店
export function syncBasicShopInfoAPI (data) {
  return request({
    url: '/api/system/o2othird/ordermeal/shop/syncBasicShopInfo',
    method: 'post',
    data
  })
}
