import request from '@/utils/request' //现烤调出
// 现烤调出列表
export function listOrder (query) {
  return request({
    url: '/api/system/freshly/baked/bill/out/list',
    method: 'get',
    params: query
  })
}

//更新现烤调出单据状态
export function updateOrderStatus (data) {
  return request({
    url: '/api/system/freshly/baked/bill/out/updateBillStatus',
    method: 'post',
    data
  })
}
//修改现烤调出单据
export function updateOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/out/update',
    method: 'PUT',
    data
  })
}

//获取现烤调出单据详情
export function getOrderDetail (billId) {
  return request({
    url: `/api/system/freshly/baked/bill/out/getDetail?billId=${billId}`
  })
}

//新增现烤调出单据
export function addOrder (data) {
  return request({
    url: '/api/system/freshly/baked/bill/out/save',
    method: 'POST',
    data
  })
}
