import request from '@/utils/request'

// 新增商城用户签到模板设计
export function signTemplateSave(data) {
    return request({
        url: '/api/system/sign/template/save',
        method: 'post',
        data
    })
}
// 签到模板详情
export function signGetDetail(data) {
    return request({
        url: '/api/system/sign/template/getDetail',
        method: 'get',
        data
    })
}
