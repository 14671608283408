import request from '@/utils/request'

//所有商品品牌
export function allBrand () {
  return request({
    url: '/api/system/goods/brand/findAll'
  })
}

// 商品品牌列表
export function listBrand (query) {
  return request({
    url: '/api/system/goods/brand/list',
    method: 'get',
    params: query
  })
}

// 查询商品品牌详细
export function getBrandDetail (brandId) {
  return request({
    url: '/api/system/goods/brand/getDetail?brandId=' + brandId,
    method: 'get'
  })
}

// 新增商品品牌
export function addBrand (data) {
  return request({
    url: '/api/system/goods/brand/save',
    method: 'post',
    data: data
  })
}

// 修改商品品牌
export function updateBrand (data) {
  return request({
    url: '/api/system/goods/brand/update',
    method: 'put',
    data: data
  })
}

// 删除商品品牌
export function delBrand (brandIds) {
  return request({
    url: '/api/system/goods/brand/remove',
    method: 'delete',
    data: brandIds
  })
}
//批量更新品牌状态
export function statusBrand (data) {
  return request({
    url: '/api/system/goods/brand/updateStatus',
    method: 'delete',
    data
  })
}
// 查询商品品牌详细
export function getGoodsBrandAutoNo () {
  return request({
    url: '/api/system/goods/brand/getGoodsBrandAutoNo',
    method: 'get'
  })
}
//还原商品品牌
export function restoreGoodsBrandInfo (brandIds) {
  return request({
    url: '/api/system/goods/brand/restoreGoodsBrandInfo',
    method: 'put',
    data: { brandIds }
  })
}
