import request from '@/utils/request'

// 商城支付配置详情
export function previewDetail(params) {
    return request({
        url: '/api/system/mall/config/preview/getDetail',
        method: 'get',
        params: params
    })
}

// 新增商城配置
export function previewSave(data) {
    return request({
        url: '/api/system/mall/config/preview/save',
        method: 'post',
        data
    })
}

// 编辑商城配置
export function previewUpdate(data) {
    return request({
        url: '/api/system/mall/config/preview/update',
        method: 'put',
        data
    })
}
