import request from '@/utils/request'


// 商品分类列表
export function cakefreeselectCategoryLiStTree (params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/category/listTree',
    method: 'get',
    params
  })
}
// 蛋糕自选商品资料详情
export function cakefreeselectGoodsGetDetail (params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/getDetail',
    method: 'get',
    params
  })
}

// 修改蛋糕自选商品资料详情
export function cakefreeselectGoodsUpdate (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/update',
    method: 'put',
    data
  })
}
// 蛋糕自选商品资料分页列表
export function cakefreeselectGoodsList (params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/list',
    method: 'get',
    params
  })
}

// 蛋糕自选过滤未上架的商品资料列表
export function listOrdermealGoods (query) {
  return request({
    url: '/api/system/goods/info/listCakefreeselectGoods',
    method: 'get',
    params: query
  })
}


// 获取蛋糕自选角标[已上线、未上线、未同步]
export function cakefreeselectGoodsBadge (params) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/getGoodsBadge',
    method: 'get',
    params
  })
}

// 获取蛋糕添加商品分类
export function cakefreeselectSaveCategory (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/category/save',
    method: 'post',
    data
  })
}

// 修改商品分类
export function cakefreeselectCategoryUpdate (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/category/update',
    method: 'put',
    data
  })
}

// 获取分类详情
export function cakefreeselectGetDetailCategory (categoryId) {
  return request({
    url: `/api/system/o2othird/cakefreeselect/category/getDetail?categoryId=${categoryId}`,
    method: 'get'
  })
}

// 删除商品分类
export function cakefreeselectRemoveCategory (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/category/remove',
    method: 'post',
    data
  })
}

// 商品批量上下架
export function cakefreeselectBatchLaunch (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/batchLaunch',
    method: 'post',
    data
  })
}

// 基础资料商品信息复制到蛋糕自选 (未上架)
export function cakefreeselectGoodsSyncBasisGoods (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/syncBasisGoods',
    method: 'post',
    data
  })
}

// 批量修改
export function cakefreeselectGoodsBatchUpdate (data) {
  return request({
    url: '/api/system/o2othird/cakefreeselect/goods/batchUpdate',
    method: 'put',
    data
  })
}

// 全部图片
export function synAllImageBasisGoodsToMall() {
  return request({
      url: '/api/system/o2othird/cakefreeselect/goods/synAllImageBasisGoodsToMall',
      method: 'post'
  })
}
// 详情图片
export function cakeSynFindImageBasisGoodsToMall(data) {
  return request({
      url: '/api/system/o2othird/cakefreeselect/goods/synFindImageBasisGoodsToMall',
      method: 'post',
      data
  })
}
